import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { RouterImplementation } from './router';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Root from './components/root';
import { HelmetProvider } from 'react-helmet-async';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Root>
        <RouterImplementation />
      </Root>
    </BrowserRouter>
  </HelmetProvider>
);

reportWebVitals();
