import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavigationDisplayType, useUser } from '../utils/context/userContext';

export default function Policy() {
  const { setDropdownItem } = useUser();
  const { removeCLeaderboard } = useUser();
  useEffect(() => {
    removeCLeaderboard();
  }, []);
  useEffect(() => {
    setDropdownItem('latest-tabs', {
      display: NavigationDisplayType.Singular,
      name: 'pages.privacy',
      href: `/privacy-policy`,
      keep: false,
      exact: true,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy Policy | FortniteRanking.com</title>
        <meta name={'description'} content={'Privacy Policy | FortniteRanking.com'} />
      </Helmet>
      <div className={'Main w-screen'}>
        <div className={'flex flex-col items-center pt-10'}>
          <article className={'prose h-full w-full max-w-6xl md:prose-lg prose-md bg-white rounded-lg shadow-md p-4 dark:bg-gray-800 dark:text-gray-300'}>
            <span className={'font-extrabold text-4xl dark:text-gray-100'}>War Legend Privacy Policy</span>
            <p className='c2'><span className='c3 c1'>This privacy policy will explain how our organisation (War Legend) uses the personal data we collect from you when you use FortniteRanking.com.</span>
            </p>
            <p className='c2'><span className='c3 c1'>Topics:</span></p>
            <ul className='c5 lst-kix_p8kzgt34cwol-0 start'>
              <li className='c0 li-bullet-0'><span className='c3 c1'>What data do we collect?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How do we collect your data?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How will we use your data?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How do we store your data?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Marketing</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>What are your data protection rights?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>What are cookies?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How do we use cookies?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>What types of cookies do we use?</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How to manage your cookies</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Privacy policies of other websites</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Changes to our privacy policy</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How to contact us</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>How to contact the appropriate authorities</span>
              </li>
            </ul>
            <h3 className='c4' id='h.fmxaztitw7bl'><span className='text-4xl dark:text-gray-100'>What data do we collect?</span></h3>
            <p className='c2'><span className='c1'>War Legend</span><span
              className='c3 c1'>&nbsp;collects the following data:</span></p>
            <ul className='c5 lst-kix_22di8is6h1i-0 start'>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Your Epic Games account ID,</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Your Epic Games account Displayname,</span></li>
              <li className='c0 li-bullet-0'><span className='c1'>Your Epic Games account access token,</span>
              </li>
              <li className='c0 li-bullet-0'><span
                className='c3 c1'>Your Epic Games account data from Fortnite Creative Maps,</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Each successful login timestamp,</span></li>
              <li className='c0 li-bullet-0'><span
                className='c3 c1'>No other data is collected.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </li>
            </ul>
            <h3 className='c4' id='h.s92rytlkthrp'><span className='text-4xl dark:text-gray-100'>How do we collect your data?</span></h3>
            <p className='c2'><span className='c3 c1'>You directly provide War Legend with most of the data we collect. We collect data and process data when you:</span>
            </p>
            <ul className='c5 lst-kix_njq8vhouu8jp-0 start'>
              <li className='c0 li-bullet-0'><span
                className='c3 c1'>Identify with your Epic Games account on our website,</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Grant access to Epic Games account on the pop-up with a code,</span>
              </li>
              <li className='c0 li-bullet-0'><span
                className='c3 c1'>Use or view our website via your browser&rsquo;s cookies,</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>When you register and are registered to a Fortnite Creative Map leaderboard.</span>
              </li>
            </ul>
            <p className='c2'><span className='c3 c1'>War Legend may also receive your data indirectly from the following sources:</span>
            </p>
            <ul className='c5 lst-kix_rr5gup9etboi-0 start'>
              <li className='c0 li-bullet-0'><span className='c3 c1'>N/A</span></li>
            </ul>
            <h3 className='c4' id='h.rdkwfyzel877'><span className='text-4xl dark:text-gray-100'>How will we use your data?</span></h3>
            <p className='c2'><span className='c1'>War Legend</span><span
              className='c3 c1'>&nbsp;collects your data so that we can:</span></p>
            <ul className='c5 lst-kix_kfdtujsbc6di-0 start'>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Manage your FortniteRanking.com account,</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Collect your progress on each map (the type of data is (not limited to) : Points, eliminations, victories, &hellip;) to create the leaderboard to which you are registered,</span>
              </li>
              <li className='c0 li-bullet-0'><span className='c1 c3'>For statistical/analytics purposes.</span></li>
            </ul>
            <p className='c2'><span className='c3 c1'>If you agree, War Legend will share your data with our partner companies for statistical/analytics purposes.</span>
            </p>
            <ul className='c5 lst-kix_fkv1wsf17xqi-0 start'>
              <li className='c0 li-bullet-0'><span
                className='c3 c1'>Epic Games Inc. ; Epic Games Entertainment International</span></li>
            </ul>
            <p className='c2'><span className='c3 c1'>When War Legend processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.</span>
            </p>
            <h3 className='c4' id='h.2d0egvsju220'><span className='text-4xl dark:text-gray-100'>How do we store your data?</span></h3>
            <p className='c2'><span className='c1'>War Legend</span><span className='c3 c1'>&nbsp;securely stores your data using advanced encryption methods and robust security protocols on datacenter-hosted ScyllaDB databases that are dedicated for War Legend&#39;s operations. Your non-sensitive data such as names, ids, and progress details are stored with an authenticated encrypted transport and can only be accessed by dedicated War Legend services. Furthermore, for sensitive data such as access tokens, an additional layer of protection is provided by encrypting and decrypting the data on the fly using post-cryptographic protocol &ldquo;IETF XChaCha20-Poly1305 AEAD&rdquo; to avoid any sensitive data leak.</span>
            </p>
            <p className='c2'><span
              className='c3 c1'>War Legend is committed to ensuring the safety and security of its users&#39; data. Adequate security measures are in place to protect your data against unauthorised access, modification, or deletion. In addition, access to your data by War Legend personnel is strictly limited and monitored. Only a select few, trained in proper data handling and privacy protocols, have access to any stored user data. Our host, Hetzner, a highly-regarded hosting provider known for its stringent security measures and robust infrastructure. Hetzner ensures the physical and network security of our servers by housing them in secure, monitored facilities with access controlled by strict security protocols.</span>
            </p>
            <p className='c2'><span className='c3 c1'>We understand the importance of your data and are dedicated to maintaining its safety and integrity. As such, War Legend continuously evaluates and upgrades security measures as necessary. We are transparent about our data practices and are committed to being a trusted and reliable service provider to our users.</span>
            </p>
            <p className='c2'><span className='c3 c1'>War Legend will keep your Epic Games account ID for 5 years. Once this time period has expired, we will delete your data by deletion of your account. This period of time is counted from your last connection to our website.</span>
            </p>
            <p className='c2'><span className='c3 c1'>War Legend will keep your Epic Games account data from each Fortnite Creative Maps for 5 years. Once this time period has expired, we will delete your data by deleting your entry in the leaderboard. This period of time is counted from your last connection to our website.</span>
            </p>
            <h3 className='c4' id='h.1fdbpxb4jjbn'><span className='text-4xl dark:text-gray-100'>Marketing</span></h3>
            <p className='c2'><span className='c1'>War Legend</span><span className='c3 c1'>&nbsp;will not use your data for Marketing purposes. But in case in the future this changes, you will be notified in advance when connecting to FortniteRanking.com.</span>
            </p>
            <h3 className='c4' id='h.um1npp26m5tq'><span className='text-4xl dark:text-gray-100'>What are your data protection rights?</span>
            </h3>
            <p className='c2'><span className='c1'>War Legend</span><span className='c3 c1'>&nbsp;would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</span>
            </p>
            <p className='c2'><span className='c7'>The right to access</span><span className='c3 c1'>&nbsp;&ndash; You have the right to request War Legend for copies of your personal data. We may charge you a small fee for this service. For security reasons, the only information we will never give you access to is your &ldquo;Epic Games account access token&rdquo;.</span>
            </p>
            <p className='c2'><span className='c7'>The right to rectification</span><span
              className='c3 c1'>&nbsp;&ndash; You have the right to request that War Legend correct any information you believe is inaccurate. You also have the right to request War Legend to complete the information you believe is incomplete.</span>
            </p>
            <p className='c2'><span className='c7'>The right to erasure</span><span className='c3 c1'>&nbsp;&ndash; You have the right to request that War Legend erase your personal data, under certain conditions.</span>
            </p>
            <p className='c2'><span className='c7'>The right to restrict processing</span><span
              className='c3 c1'>&nbsp;&ndash; You have the right to request that War Legend restrict the processing of your personal data, under certain conditions.</span>
            </p>
            <p className='c2'><span className='c7'>The right to object to processing</span><span
              className='c3 c1'>&nbsp;&ndash; You have the right to object to War Legend&rsquo;s processing of your personal data, under certain conditions.</span>
            </p>
            <p className='c2'><span className='c7'>The right to data portability</span><span
              className='c3 c1'>&nbsp;&ndash; You have the right to request that War Legend transfer the data that we have collected to another organization, or directly to you, under certain conditions.</span>
            </p>
            <p className='c2'><span className='c3 c1'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: </span>
            </p>
            <ul className='c5 lst-kix_720wjh3px59o-0 start'>
              <li className='c2 c6 li-bullet-0'><span className='c1'>English: </span><span
                className='c1'>gdpr@warlegend.gg</span><span
                className='c3 c1'>&nbsp;</span></li>
              <li className='c2 c6 li-bullet-0'><span className='c3 c1'>French: rgpd-fr@warlegend.gg</span></li>
            </ul>
            <p className='c2'><span className='c3 c1'>Or write to us: </span></p>
            <p className='c2'><span
              className='c3 c1'>War Legend<br />82 boulevard de Grenelle<br />75015 Paris<br />France</span></p>
            <h3 className='c4' id='h.wuou4c181jon'><span className='text-4xl dark:text-gray-100'>Cookies</span></h3>
            <p className='c2'><span className='c3 c1'>Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology</span>
            </p>
            <p className='c2'><span className='c3 c1'>For further information, visit allaboutcookies.org.</span></p>
            <h3 className='c4' id='h.yg1xzzkaqwdr'><span className='text-4xl dark:text-gray-100'>How do we use cookies?</span></h3>
            <p className='c2'><span className='c1'>War Legend</span><span className='c3 c1'>&nbsp;uses cookies in a range of ways to improve your experience on our website, including:</span>
            </p>
            <ul className='c5 lst-kix_2kp4xakxgall-0 start'>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Keeping you signed in</span></li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Understanding how you use our website</span></li>
            </ul>
            <h3 className='c4' id='h.92j4pcau9jgx'><span className='text-4xl dark:text-gray-100'>What types of cookies do we use?</span></h3>
            <p className='c2'><span className='c3 c1'>There are a number of different types of cookies, however, our website uses:</span>
            </p>
            <ul className='c5 lst-kix_vc019rplp54n-0 start'>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Functionality &ndash; War Legend uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.</span>
              </li>
              <li className='c0 li-bullet-0'><span className='c3 c1'>Advertising &ndash; War Legend uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. War Legend sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.</span>
              </li>
            </ul>
            <h3 className='c4' id='h.74so5pfn3vkn'><span className='text-4xl dark:text-gray-100'>How to manage cookies</span></h3>
            <p className='c2'><span className='c3 c1'>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</span>
            </p>
            <h3 className='c4' id='h.v9hashzafywv'><span className='text-4xl dark:text-gray-100'>Privacy policies of other websites</span>
            </h3>
            <p className='c2'><span className='c3 c1'>The War Legend website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</span>
            </p>
            <h3 className='c4' id='h.f8nak1qwujib'><span className='text-4xl dark:text-gray-100'>Changes to our privacy policy</span></h3>
            <p className='c2'><span className='c1'>War Legend</span><span className='c3 c1'>&nbsp;keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 9 January 2019.</span>
            </p>
            <h3 className='c4' id='h.xvulgbqlpzff'><span className='text-4xl dark:text-gray-100'>How to contact us</span></h3>
            <p className='c2'>
              <span className='c1'>
                If you have any questions about War Legend&rsquo;s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.<br />Email us at: </span><span
              className='c1 c15'><a className='c17 dark:text-gray-300'
                                    href='mailto:rgpd-fr@warlegend.gg'>rgpd-fr@warlegend.gg</a></span><span
              className='c3 c1'><br />Or write to us: <br />War Legend<br />82 boulevard de Grenelle<br />75015 Paris</span>
            </p>
            <h3 className='c4' id='h.x43qhykqut3k'><span
              className='text-4xl dark:text-gray-100'>How to contact the appropriate authority</span></h3>
            <p className='c2'><span className='c3 c1'>Should you wish to report a complaint or if you feel that War Legend has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner&rsquo;s Office.</span>
            </p>
            <p className='c2'><span className='c8 c3'>For France (CNIL)<br />+33 (0)1 53 73 22 22</span></p>
            <p className='c2'><span
              className='c8 c3'>CNIL<br />3 Place de Fontenoy<br />TSA 80715<br />75334 PARIS CEDEX 07<br />FRANCE</span>
            </p>
          </article>
        </div>
      </div>
    </>
  );
}
