import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Locale Files
import EN from './locales/en.json';
import FR from './locales/fr.json';

const resources = {
  en: {
    translation: EN,
  },
  fr: {
    translation: FR,
  },
};

const detection = {
  order: [
    'querystring',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
  lookupQuerystring: 'lang',
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    load: 'languageOnly',
    resources,
    debug: false,
    supportedLngs: ['en', 'fr'],
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    react: {
      useSuspense: false,
    },
    returnEmptyString: true,
    detection,
  });

export default i18n;

export interface ILang {
  name: string;
  code: string;
  flag: string;
}

export const languages: ILang[] = [
  {
    name: 'English',
    code: 'en',
    flag: 'gb',
  },
  {
    name: 'Français',
    code: 'fr',
    flag: 'fr',
  },
];
