import * as process from 'process';
import { JerichoMe } from '../interfaces/requests';
import { FavoriteLeaderboard } from '../interfaces/leaderboard';
import { IContext } from './context/userContext';

export class AccountsService {
  private static URL_OAUTH = 'https://accounts.wls.gg/oauth/authorize';
  private static URL_TOKEN = `${process.env.REACT_APP_API_URL ?? ''}/oauth/token`;
  private static URL_ME = `${process.env.REACT_APP_API_URL ?? ''}/users/@me`;
  private static URL_REVOKE = `${process.env.REACT_APP_API_PRE_URL ?? ''}/api/v5/oauth/revoke`;
  private static URL_JERICHO = `${process.env.REACT_APP_API_PRE_URL ?? ''}/api/v5/jericho`;

  public static async getToken(code: string, redirect: string): Promise<string> {
    const response = await fetch(this.URL_TOKEN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        code,
        client_id: process.env.REACT_APP_ACCOUNTS_CLIENT_ID,
        client_secret: process.env.REACT_APP_ACCOUNTS_CLIENT_SECRET,
        redirect_uri: redirect,
      } as unknown as URLSearchParams),
    });
    const data = await response.json();
    if (!data.access_token) throw new Error('Invalid access token');
    return data.access_token;
  }

  public static getAuthUrl(redirect: string): string {
    return `${this.URL_OAUTH}?client_id=${process.env.REACT_APP_ACCOUNTS_CLIENT_ID}&response_type=code&redirect_uri=${redirect}&scope=users`;
  }

  public static async logout(token: string): Promise<void> {
    await fetch(this.URL_REVOKE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_ACCOUNTS_CLIENT_ID,
        token,
        token_type_hint: 'access_token',
      } as unknown as URLSearchParams),
    });
  }

  static async me(token: string): Promise<IContext> {
    const response = await fetch(this.URL_ME, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!data.id) throw new Error('Invalid authorization token');
    return data;
  }

  static async meJericho(token: string): Promise<JerichoMe> {
    const response = await fetch(`${this.URL_JERICHO}/@me`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!data.ingame_id) throw new Error('Invalid authorization token');
    return data;
  }

  static async joinLeaderboard(token: string, leaderboard: string): Promise<void> {
    const response = await fetch(`${this.URL_JERICHO}/@me/leaderboards/${leaderboard}/join`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error('Invalid authorization token');
  }

  static async leaveLeaderboard(token: string, leaderboard: string): Promise<void> {
    const response = await fetch(`${this.URL_JERICHO}/@me/leaderboards/${leaderboard}/leave`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error('Invalid authorization token');
  }

  static async getFavorites(token: string): Promise<FavoriteLeaderboard[]> {
    const response = await fetch(`${this.URL_JERICHO}/@me/favorites`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (!data.results) throw new Error('Invalid authorization token');
    return data.results;
  }

  static async addFavorite(token: string, favorite: string): Promise<void> {
    const response = await fetch(`${this.URL_JERICHO}/@me/favorites/${favorite}`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error('Invalid authorization token');
  }

  static async removeFavorite(token: string, favorite: string): Promise<void> {
    const response = await fetch(`${this.URL_JERICHO}/@me/favorites/${favorite}`, {
      method: 'DELETE',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error('Invalid authorization token');
  }

  static async launchIsland(token: string, island: string): Promise<'queued' | 'notified'> {
    const response = await fetch(`${this.URL_JERICHO}/@me/launch_island/${island}`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw new Error('Invalid authorization token');
    const data = await response.json();
    return data.status;
  }
}
