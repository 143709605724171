import React from 'react';
import { useUser } from '../utils/context/userContext';

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { darkTheme, currentLeaderboard } = useUser();
  return (
    <div
      className={darkTheme ? 'dark' : 'light'}
      style={{
        backgroundImage: currentLeaderboard?.background_settings?.background_image ? `url(${currentLeaderboard.background_settings.background_image})` : undefined,
        backgroundSize: 'cover',
      }}
    >
      {children}
    </div>
  );
}
