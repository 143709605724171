import { useEffect, useState } from 'react';

const useCountdown = (targetDate: string, startDate: number | null) => {
  const countDownDate = new Date(targetDate).getTime();
  const nowDate = startDate ? new Date(startDate).getTime() : new Date().getTime();
  let secondsFrom = 0;

  const [countDown, setCountDown] = useState(
    countDownDate - nowDate,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - nowDate - secondsFrom * 1000);
      secondsFrom += 1;
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };
