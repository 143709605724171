import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { TwitterButton } from '../components/logos/twitterButton';
import { DiscordButton } from '../components/logos/discordButton';
import { useUser } from '../utils/context/userContext';
import { Link } from 'react-router-dom';
import { ContactButton } from '../components/logos/contactButton';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from '../utils/helpers';

export default function Main() {
  const { removeCLeaderboard } = useUser();
  const { t } = useTranslation();
  useEffect(() => {
    removeCLeaderboard();
  }, []);
  return (
    <>
      <Helmet>
        <title>Home | FortniteRanking.com</title>
        <meta name={'description'} content={'FortniteRanking.com is a Fortnite Creative Leaderboards tool.'} />
        <meta name={'keywords'}
              content={'Fortnite Ranking, War Legend, Creative, Leaderboards, Ranking, Fortnite, Epic Games, Fortnite Creative, Fortnite Creative Leaderboards'} />
        <meta name={'theme-color'} content={'#000000'} />
        <meta name={'robots'} content={'index, follow'} />
        <meta name={'viewport'} content={'width=device-width, initial-scale=1.0'} />
        <meta name={'og:title'} content={'FortniteRanking.com'} />
        <meta name={'og:description'} content={'FortniteRanking.com is a Fortnite Creative Leaderboards tool.'} />
        <meta name={'og:image'} content={'https://fortniteranking.com/logo.png'} />
        <meta name={'og:url'} content={'https://fortniteranking.com/'} />
        <meta name={'og:site_name'} content={'FortniteRanking.com'} />
        <meta name={'og:type'} content={'website'} />
        <meta name={'og:locale'} content={'en_US'} />
        <meta name={'twitter:card'} content={'summary_large_image'} />
        <meta name={'twitter:site'} content={'@WLFortnite'} />
        <meta name={'twitter:creator'} content={'@WLFortnite'} />
        <meta name={'twitter:title'} content={'FortniteRanking.com'} />
        <meta name={'twitter:description'} content={'FortniteRanking.com is a Fortnite Creative Leaderboards tool.'} />
        <meta name={'twitter:image'} content={'https://fortniteranking.com/logo.png'} />
        <meta name={'twitter:url'} content={'https://fortniteranking.com/'} />
        <meta name={'twitter:domain'} content={'fortniteranking.com'} />
      </Helmet>
      <div className={'Main w-screen min-h-[calc(100vh-18rem)]'}>
        <div className={'w-full h-full flex flex-col items-center pt-10'}>
          <div
            className={'w-full h-full flex flex-col md:flex-row items-center pt-10 justify-center space-x-0 md:space-x-10 z-[1]'}>
            <div>
              <h1 className={'sm:text-5xl font-extrabold pl-1 text-3xl'}>
                FortniteRanking.com
              </h1>
              <h3 className={'text-2xl mt-10 max-w-[32rem] mx-auto'}>
                <Trans
                  i18nKey={'main.welcome'}
                  values={{
                    name: t('name'),
                    game: 'Fortnite Creative / UEFN 2.0',
                  }}
                >
                  Welcome to <b>name</b>. A tool used to create and manage tournaments
                  in <span className={'gradient-text'}>Fortnite Creative / UEFN 2.0</span>.
                </Trans>
                <br /><br />
                <Trans
                  i18nKey={'main.request'}
                >
                  This tool is currently open only to a few, request access on <span
                  className={'gradient-text'}>our Discord</span> or <Link to={'/contact'} className={'gradient-text'}>contact
                  us</Link> for more information.
                </Trans>
              </h3>
              <div
                className={'flex flex-row space-x-2 md:space-x-5 mt-5 items-center justify-center max-w-[30rem] mx-auto'}>
                <ContactButton
                  href={'/contact'}
                  input={t('pages.contact')}
                />
                <TwitterButton
                  href={'https://x.com/intent/follow?screen_name=WLFortnite'}
                  input={'X / Twitter'}
                />
                <DiscordButton
                  href={'https://discord.gg/SDTbDHbZ9w'}
                  input={'Discord'}
                />
              </div>


              {/*<Link*/}
              {/*  to={'/jumbo'}*/}
              {/*  className={classNames('flex flex-col sm:flex-row items-center justify-between space-x-2 mt-10 p-2 rounded-3xl bg-indigo-500 text-gray-100 hover:bg-indigo-600 hover:text-gray-200 max-w-[22rem] mx-auto',*/}
              {/*    'text-sm sm:text-base hover:shadow-md',*/}
              {/*    'dark:bg-indigo-500 dark:text-gray-100 dark:hover:bg-indigo-600 dark:hover:text-gray-200',*/}
              {/*    'transition duration-300 ease-in-out scale-95 hover:scale-100')}*/}
              {/*  role='alert'*/}
              {/*>*/}
              {/*  <div className={'flex flex-row items-center'}>*/}
              {/*    <span className={'flex rounded-full text-left bg-gray-500 uppercase px-2 py-1 text-xs font-bold mr-3'}>*/}
              {/*      ENDED*/}
              {/*    </span>*/}
              {/*    <img*/}
              {/*      src={`/images/fr.svg`}*/}
              {/*      alt={'Flag'}*/}
              {/*      className='h-6 w-6 sm:hidden block'*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <span*/}
              {/*    className={'font-semibold mr-2 text-left flex-auto flex flex-row items-center justify-between pr-2'}>*/}
              {/*    JUMBO GAME Tournament*/}
              {/*    <img*/}
              {/*      src={`/images/fr.svg`}*/}
              {/*      alt={'Flag'}*/}
              {/*      className='h-6 w-6 hidden sm:block'*/}
              {/*    />*/}
              {/*  </span>*/}
              {/*</Link>*/}

              <Link
                to={'/fishermansfriend'}
                className={classNames('flex flex-col sm:flex-row items-center justify-between space-x-2 mt-10 p-2 rounded-3xl bg-indigo-500 text-gray-100 hover:bg-indigo-600 hover:text-gray-200 max-w-[22rem] mx-auto',
                  'text-sm sm:text-base hover:shadow-md',
                  'dark:bg-indigo-500 dark:text-gray-100 dark:hover:bg-indigo-600 dark:hover:text-gray-200',
                  'transition duration-300 ease-in-out scale-95 hover:scale-100')}
                role='alert'
              >
                <div className={'flex flex-row items-center'}>
                  <span
                    className={'flex rounded-full text-left bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3'}>
                    LIVE
                  </span>
                  <img
                    src={`/images/fr.svg`}
                    alt={'Flag'}
                    className='h-6 w-6 sm:hidden block'
                  />
                </div>
                <span
                  className={'font-semibold mr-2 text-left flex-auto flex flex-row items-center justify-between pr-2'}>
                  Fishermans Friend Gaming
                </span>
              </Link>
            </div>
          </div>
          <div className={'absolute top-10 overflow-hidden flex flex-col box-border w-full opacity-70'}>
            <Trans
              i18nKey={'main.created'}
              values={{
                creator: t('creator'),
              }}
            >
              <span className={'font-extrabold text-gray-900 opacity-10 text-outline text-[10rem] items-left ml-10'}>
                Created
              </span>
              <span
                className={'font-extrabold text-gray-900 opacity-10 text-outline text-[8rem] items-center text-center'}>
                  By
              </span>
              <span
                className={'font-extrabold text-gray-900 opacity-10 text-outline text-[10rem] items-right mr-10 text-center'}>
                WarLegend
              </span>
            </Trans>
          </div>
        </div>
      </div>
    </>
  );
}
