import { toast } from 'react-toastify';

type Init = {
  noErrorToast?: boolean;
} & RequestInit;

async function api(url: string, options: Init = {}): Promise<any> {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('WCA-TOKEN')}`,
      ...options.headers,
    };
    const body = options.body ?? {};
    if (options.method !== 'GET') {
      options.body = JSON.stringify(body);
    }
    const requestUrl = `${process.env.REACT_APP_API_URL}${url}`;
    const response = await fetch(requestUrl, {
      ...options,
      headers,
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      if (data.message === 'Invalid authorization token')
        localStorage.removeItem('WCA-TOKEN');
      if (!options.noErrorToast)
        toast.error(data.message);
      options.noErrorToast = true;
      throw data;
    }
  } catch (error) {
    if (!options.noErrorToast)
      toast.error('Something went wrong. Please try again.');
    throw error;
  }
}

export default {
  get: (url: string, options: Init = {}) => api(url, { ...options, method: 'GET' }),
  post: (url: string, options: Init = {}) => api(url, { ...options, method: 'POST' }),
  put: (url: string, options: Init = {}) => api(url, { ...options, method: 'PUT' }),
  delete: (url: string, options: Init = {}) => api(url, { ...options, method: 'DELETE' }),
};
