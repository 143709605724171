import React, { Fragment, useRef } from 'react';
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import LogoIcon from './logos/logoIcon';
import { classNames } from '../utils/helpers';
import {
  getEGName,
  NavigationDisplayType,
  NavigationItem,
  NavigationItemType,
  useUser,
} from '../utils/context/userContext';
import EpicGames from './logos/epicgames';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChevronDownIcon,
  ChevronUpIcon,
  HomeIcon,
  LinkIcon,
  MoonIcon,
  QueueListIcon,
  SunIcon,
  TrophyIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { AccountsService } from '../utils/accountsService';
import { Trans, useTranslation } from 'react-i18next';
import { default as i18n, languages } from '../i18n';

export default function Navigation() {
  const { t } = useTranslation();
  const itemsRef = useRef<React.RefObject<HTMLButtonElement>[]>([]);
  const {
    isLogged,
    isLoading,
    user,
    fetchUser,
    navigation,
    removeDropdownItem,
    currentLeaderboard,
    darkTheme,
    switchTheme,
  } = useUser();
  const { pathname } = useLocation();
  const timeoutDuration = 200;
  let timeout: NodeJS.Timeout | null = null;

  const buttonRefGetter = (index: number) => {
    if (!itemsRef.current[index]) {
      itemsRef.current[index] = React.createRef();
    }
    return itemsRef.current[index];
  };

  const closePopover = (index: number) => {
    return buttonRefGetter(index).current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      }),
    );
  };

  const LanguagePicker = () => {
    return (
      <Menu as='div' className='relative'>
        <div>
          <Menu.Button
            className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300',
              'scale-90 hover:scale-110 focus:outline-none transition ease-in-out duration-300')}
          >
            <span className='sr-only'>Open language menu</span>
            <span className='inline-flex items-center'>
                <span className='text-white text-xs'>
                  <img
                    src={`/images/${languages.find(lang => lang.code === i18n.languages[0])?.flag}.svg`}
                    alt={i18n.language}
                    className='h-6 w-6'
                  />
                </span>
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-900 text-white'
          >
            <div className='flex flex-col p-2 space-y-1'>
              {
                languages.map((lang) => (
                  <Menu.Item key={lang.code}>
                    {({ active }) => (
                      <button
                        type='button'
                        onClick={() => i18n.changeLanguage(lang.code)}
                        className={classNames(
                          active ? 'bg-gray-700' : '',
                          'flex px-4 py-2 text-sm text-white pb-2.5 pt-2.5 items-center justify-between',
                          'transition ease-in-out duration-150',
                        )}
                      >
                        <img
                          src={`/images/${lang.flag}.svg`}
                          alt={lang.code}
                          className='h-6 w-6'
                        />
                        {lang.name}
                      </button>
                    )}
                  </Menu.Item>
                ))
              }
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const onMouseEnter = (open: boolean, index: number) => {
    if (timeout !== null)
      clearTimeout(timeout);
    if (open) return;
    return buttonRefGetter(index).current?.click();
  };

  const onMouseLeave = (open: boolean, index: number) => {
    if (!open) return;
    timeout = setTimeout(() => closePopover(index), timeoutDuration);
  };

  const isCurrentItem = (item: NavigationItem): boolean => {
    if (item.display === NavigationDisplayType.Singular) {
      const { exact, href } = item;
      if (exact)
        return pathname === href;
      return pathname.startsWith(href);
    } else if (item.display === NavigationDisplayType.Dropdown) {
      return item.items.some((subItem) => isCurrentItem(subItem));
    } else {
      return false;
    }
  };

  const verifyAccountsLogin = async (newWindow: Window | null) => {
    if (!newWindow) {
      return;
    }
    const timer = setInterval(async () => {
      if (newWindow.closed) {
        clearInterval(timer);
        const code = localStorage.getItem('WCA-CODE');
        localStorage.removeItem('WCA-CODE');
        if (!code) {
          toast.error(t('nav.login.closed'));
          return;
        }
        const token = await AccountsService.getToken(code, `${window.location.origin}/auth/callback`);
        if (!token) {
          toast.error(t('nav.login.error'));
          return;
        }

        localStorage.setItem('WCA-TOKEN', token);
        await fetchUser();
      }
    }, 500);
  };

  const createAccountsLogin = async () => {
    try {
      const oauthUrl = AccountsService.getAuthUrl(`${window.location.origin}/auth/callback`);
      const newWindow = window.open(
        oauthUrl,
        '_blank',
        'width=500,height=800,toolbar=0,location=0,menubar=0',
      );

      await verifyAccountsLogin(newWindow);
    } catch (e) {
      toast.error(t('nav.login.error'));
    }
  };

  const logout = async () => {
    localStorage.removeItem('WCA-TOKEN');
    await fetchUser();
  };

  const manageAccount = async () => {
    window.open(
      'https://accounts.wls.gg/',
      '_blank',
    );
  };

  function ItemPick({ item }: { item: NavigationItem }) {
    return (
      <>
        {item.type === NavigationItemType.Home ? (
          <HomeIcon
            className='h-5 w-5 mr-0.5'
            aria-hidden='true'
          />
        ) : item.type === NavigationItemType.Leaderboard ? (
          <QueueListIcon
            className='h-5 w-5 mr-0.5'
            aria-hidden='true'
          />
        ) : item.type === NavigationItemType.Tournament ? (
          <TrophyIcon
            className='h-5 w-5 mr-0.5'
            aria-hidden='true'
          />
        ) : item.type === NavigationItemType.Latest ? (
          <ArrowPathIcon
            className='h-5 w-5 mr-0.5'
            aria-hidden='true'
          />
        ) : (
          <LinkIcon
            className='h-5 w-5 mr-0.5'
            aria-hidden='true'
          />
        )}
      </>
    );
  }

  return (
    <>
      <Disclosure
        as={'nav'}
        className={classNames('bg-gray-800 relative z-[10]', `${currentLeaderboard?.navbar_settings?.navbar_color_bg || ''}`)}
        style={{
          backgroundImage: `url(${currentLeaderboard?.navbar_settings?.navbar_image_bg ? `/images/${currentLeaderboard.navbar_settings.navbar_image_bg}` : ''})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {({ open }) => (
          <>
            <div className='mx-auto px-2 sm:px-4 lg:px-6'>
              <div className='relative flex items-center justify-between h-14'>
                <div className='flex h-12 items-center justify-between w-screen'>
                  <div className='flex items-center h-full overflow-ellipsis overflow-hidden whitespace-nowrap'>
                    <div className='text-white text-2xl font-bold flex items-center'>
                      <Link
                        to={currentLeaderboard?.navbar_settings?.navbar_title_redirect_disabled ? '#' : '/'}
                        className='flex items-center h-full mr-2'
                      >
                        <Link
                          to={'/'}
                          className='w-max'
                        >
                          <LogoIcon
                            className={classNames('h-8 w-auto mr-1 text-white fill-current rounded-full', `${currentLeaderboard?.navbar_settings?.navbar_logo_class || ''}`)}
                            aria-hidden='true'
                            src={currentLeaderboard?.navbar_settings?.navbar_logo ? `/images/${currentLeaderboard.navbar_settings.navbar_logo}` : undefined}
                          />
                        </Link>
                        <span
                          className='block text-gray-200 font-bold md:text-xl text-sm overflow-hidden whitespace-nowrap'>
                      {currentLeaderboard?.navbar_settings?.navbar_title ?? t('name')}
                    </span>
                      </Link>
                      <Link
                        to='https://wls.gg/'
                        target={'_blank'}
                        className='hidden items-center h-full overflow-ellipsis overflow-hidden whitespace-nowrap text-sm font-bold xl:flex'
                      >
                  <span
                    className='md:text-sm text-xs overflow-ellipsis overflow-hidden whitespace-nowrap gradient-text'>
                    {t('createdBy', { creator: t('creator') })}
                  </span>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={!currentLeaderboard?.navbar_settings?.navigation_hidden ? 'hidden lg:block h-8 overflow-ellipsis overflow-hidden whitespace-nowrap' : 'hidden'}>
                    <div
                      className='flex items-baseline space-x-4 h-full overflow-ellipsis overflow-hidden whitespace-nowrap'>
                      {
                        navigation.map((item, index) => (
                          <>
                            {item.display === NavigationDisplayType.Singular && (
                              <Link
                                key={index}
                                to={item.href}
                                className={classNames(
                                  'text-white bg-gray-800 hover:bg-gray-700 rounded-md px-3 py-1.5',
                                  'text-xs 2xl:text-sm font-medium h-full px-3 flex items-center relative overflow-ellipsis overflow-hidden whitespace-nowrap',
                                  'transition ease-in-out duration-300',
                                  isCurrentItem(item) ? 'animation-border' : '',
                                )}
                                aria-current={isCurrentItem(item) ? 'page' : undefined}
                              >
                                <ItemPick item={item} />
                                {t(item.name)}
                              </Link>
                            )}
                            {item.display === NavigationDisplayType.Dropdown && (!item.no_items || item.items.length > 0) && (
                              <Popover key={index} className={'relative'}>
                                {({ open }) => (
                                  <div onMouseLeave={onMouseLeave.bind(null, open, index)}>
                                    <Popover.Button
                                      ref={buttonRefGetter(index)}
                                      className={classNames(
                                        'text-white bg-gray-800 hover:bg-gray-700 rounded-md px-3 py-1.5',
                                        'text-xs 2xl:text-sm font-medium h-full px-3 flex items-center relative overflow-ellipsis overflow-hidden whitespace-nowrap',
                                        'transition ease-in-out duration-300 focus:outline-none',
                                        isCurrentItem(item) ? 'animation-border' : '',
                                      )}
                                      onMouseEnter={onMouseEnter.bind(null, open, index)}
                                      onMouseLeave={onMouseLeave.bind(null, open, index)}
                                    >
                                      <ItemPick item={item} />
                                      {t(item.name)}
                                      {open ? (
                                        <ChevronUpIcon
                                          className={'h-5 w-5 transform transition ease-in-out duration-300 ml-1'}
                                          aria-hidden={'true'}
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          className={'h-5 w-5 transform transition ease-in-out duration-300 ml-1'}
                                          aria-hidden={'true'}
                                        />
                                      )}
                                    </Popover.Button>
                                    <Transition
                                      as={Fragment}
                                      enter={'transition ease-out duration-100'}
                                      enterFrom={'transform opacity-0 scale-95'}
                                      enterTo={'transform opacity-100 scale-100'}
                                      leave={'transition ease-in duration-75'}
                                      leaveFrom={'transform opacity-100 scale-100'}
                                      leaveTo={'transform opacity-0 scale-95'}
                                    >
                                      <Popover.Panel
                                        className={'fixed z-50 origin-top-right rounded-md shadow-lg focus:outline-none bg-gray-900 text-white'}
                                        onMouseEnter={onMouseEnter.bind(null, open, index)}
                                        onMouseLeave={onMouseLeave.bind(null, open, index)}
                                      >
                                        <div
                                          className={classNames('rounded-lg shadow-lg ring-1 ring-black ring-opacity-5', 'bg-gray-900 text-white')}
                                        >
                                          <div className={'flex flex-col p-2 space-y-1'}>
                                            {
                                              item.items.map((subItem) => (
                                                <div
                                                  key={subItem.name}
                                                  className={'flex items-center space-x-1 h-full justify-between bg-gray-800 hover:bg-gray-700 rounded-md'}
                                                >
                                                  <Link
                                                    to={subItem.href}
                                                    onClick={onMouseLeave.bind(null, open, index)}
                                                    className={classNames(
                                                      'text-white px-3 py-1.5 rounded-md w-full',
                                                      'text-xs 2xl:text-sm font-medium h-full px-3 flex items-center relative overflow-ellipsis overflow-hidden whitespace-nowrap',
                                                      'transition ease-in-out duration-300',
                                                      !subItem.keep ? 'justify-between min-w-[15rem]' : 'min-w-[10rem] justify-center',
                                                      isCurrentItem(subItem) ? 'animation-border' : '',
                                                    )}
                                                    aria-current={isCurrentItem(subItem) ? 'page' : undefined}
                                                  >
                                                    <ItemPick item={subItem} />
                                                    {t(subItem.name)}
                                                    {!subItem.keep && (
                                                      <button
                                                        type={'button'}
                                                        onClick={event => {
                                                          event.stopPropagation();
                                                          event.preventDefault();
                                                          removeDropdownItem(item.id, subItem.href);
                                                        }}
                                                        className={'text-gray-400 hover:text-white focus:outline-none'}
                                                      >
                                                        <XMarkIcon
                                                          className={'h-5 w-5'}
                                                          aria-hidden={'true'}
                                                        />
                                                      </button>
                                                    )}
                                                  </Link>
                                                </div>
                                              ))
                                            }
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Transition>
                                  </div>
                                )}
                              </Popover>
                            )}
                          </>
                        ))
                      }
                    </div>
                  </div>
                  <>
                    {
                      !isLogged && !isLoading && (
                        <>
                          <div className='hidden lg:flex relative ml-3 flex-row'>
                            <LanguagePicker />
                            {
                              darkTheme ? (
                                <button
                                  type='button'
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300 hover:scale-110 scale-90')}
                                  onClick={() => switchTheme()}
                                  aria-label='Switch to light theme'
                                >
                                  <SunIcon
                                    className='h-6 w-6 text-white fill-current'
                                    aria-hidden='true'
                                  />
                                </button>
                              ) : (
                                <button
                                  type='button'
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300  hover:scale-110 scale-90')}
                                  onClick={() => switchTheme()}
                                  aria-label='Switch to dark theme'
                                >
                                  <MoonIcon
                                    className='h-6 w-6 text-white fill-current'
                                    aria-hidden='true'
                                  />
                                </button>
                              )
                            }
                            <button
                              type='button'
                              className={classNames(
                                'text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300 overflow-ellipsis overflow-hidden whitespace-nowrap',
                                `${currentLeaderboard?.navbar_settings?.navbar_buttons_class || 'bg-gray-700 hover:bg-gray-600'}`)}
                              onClick={() => createAccountsLogin()}
                            >
                              <EpicGames
                                className='h-6 w-6 mr-2 text-white fill-current'
                                aria-hidden='true'
                              />
                              {t('nav.login.signIn')}
                            </button>
                          </div>
                        </>
                      )
                    }
                    {
                      isLoading && (
                        <>
                          <div className='hidden lg:block relative ml-3'>
                            <button
                              type='button'
                              className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center overflow-ellipsis overflow-hidden whitespace-nowrap w-28 h-10',
                                `${currentLeaderboard?.navbar_settings?.navbar_buttons_class || 'bg-gray-700'}`)}
                              disabled={true}
                            >
                            </button>
                          </div>
                        </>
                      )
                    }
                    {
                      isLogged && (
                        <>
                          <div className='hidden lg:flex relative ml-3 flex-row'>
                            <LanguagePicker />
                            {
                              darkTheme ? (
                                <button
                                  type='button'
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300 hover:scale-110 scale-90')}
                                  onClick={() => switchTheme()}
                                  aria-label='Switch to light theme'
                                >
                                  <SunIcon
                                    className='h-6 w-6 text-white fill-current'
                                    aria-hidden='true'
                                  />
                                </button>
                              ) : (
                                <button
                                  type='button'
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300  hover:scale-110 scale-90')}
                                  onClick={() => switchTheme()}
                                  aria-label='Switch to dark theme'
                                >
                                  <MoonIcon
                                    className='h-6 w-6 text-white fill-current'
                                    aria-hidden='true'
                                  />
                                </button>
                              )
                            }
                            <Menu as='div' className='relative ml-3 hidden lg:block'>
                              <div>
                                <Menu.Button
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300',
                                    `${currentLeaderboard?.navbar_settings?.navbar_buttons_class || 'bg-gray-800 hover:bg-gray-700'}`)}
                                >
                                  <span className='sr-only'>Open user menu</span>
                                  <UserCircleIcon className='h-6 w-6 rounded-full text-white ml-0 mr-2' />
                                  <span className={'block'}>
                                    {getEGName(user)}
                                  </span>
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'
                              >
                                <Menu.Items
                                  className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-gray-900 text-white'>
                                  <Menu.Item>
                                    <div className='items-center px-4 py-3 border-b border-gray-700'>
                                      <p className='text-sm font-medium'>
                                        <Trans
                                          i18nKey={'nav.login.loggedAs'}
                                          values={{
                                            name: getEGName(user),
                                          }}
                                        >
                                          You are logged as:
                                          <span className={'gradient-text font-bold'}>{' '}{getEGName(user)}</span>
                                        </Trans>
                                      </p>
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        onClick={() => manageAccount()}
                                        to={'?'}
                                        className={classNames(
                                          active ? 'bg-gray-700' : '',
                                          'block px-4 py-2 text-sm text-white pb-2.5 pt-2.5',
                                          'transition ease-in-out duration-150',
                                        )}
                                      >
                                        {t('nav.login.manage')}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        onClick={() => logout()}
                                        to={'?'}
                                        className={classNames(
                                          active ? 'bg-gray-700 rounded-b-md' : '',
                                          'block px-4 py-2 text-sm text-red-500',
                                          'font-bold pb-2.5 pt-2.5',
                                          'transition ease-in-out duration-150',
                                        )}
                                      >
                                        {t('nav.login.logout')}
                                      </Link>
                                    )}
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </>
                      )
                    }
                    <div className='lg:-mr-2 flex lg:hidden'>
                      <Disclosure.Button
                        className={classNames('inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none',
                          'transition ease-in-out duration-300',
                          `${currentLeaderboard?.navbar_settings?.navbar_buttons_class || 'bg-gray-800 hover:bg-gray-700'}`)}
                      >
                                <span className='sr-only'>
                                  {open ? 'Close main menu' : 'Open main menu'}
                                </span>
                        {open ? (
                          <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                        ) : (
                          <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                        )}
                      </Disclosure.Button>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <Disclosure.Panel className='lg:hidden'>
              <div className='space-y-1 px-2 pt-2 pb-3 sm:px-3'>
                {
                  navigation.map((item) => (
                    <>
                      {item.display === NavigationDisplayType.Singular && (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            'text-white',
                            'text-sm font-medium h-full px-3 flex items-center relative py-2 rounded-md',
                            'transition ease-in-out duration-300',
                            isCurrentItem(item) ? 'animation-border' : '',
                          )}
                          aria-current={isCurrentItem(item) ? 'page' : undefined}
                        >
                          <ItemPick item={item} />
                          {t(item.name)}
                        </Link>
                      )}
                    </>
                  ))
                }
                {
                  navigation.map((item, index) => (
                    <>
                      {item.display === NavigationDisplayType.Dropdown && (!item.no_items || item.items.length > 0) && (
                        <Disclosure key={index}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  'text-white',
                                  'text-sm font-medium h-full w-full px-3 flex items-center relative py-2 rounded-md',
                                  'transition ease-in-out duration-300',
                                  isCurrentItem(item) ? 'animation-border' : '',
                                )}
                              >
                                <ItemPick item={item} />
                                {t(item.name)}
                                <svg
                                  className={classNames(
                                    open ? '-rotate-180' : 'rotate-0',
                                    'ml-auto h-5 w-5 transform transition ease-in-out duration-300',
                                  )}
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                  aria-hidden='true'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                                  />
                                </svg>
                              </Disclosure.Button>
                              <Disclosure.Panel className='space-y-1 px-2 pt-2 pb-3 sm:px-3'>
                                {
                                  item.items.map((subItem) => (
                                    <Link
                                      key={subItem.name}
                                      to={subItem.href}
                                      className={classNames(
                                        'text-white',
                                        'text-sm font-medium h-full px-3 flex items-center relative py-2 rounded-md',
                                        'transition ease-in-out duration-300',
                                        isCurrentItem(subItem) ? 'animation-border' : '',
                                      )}
                                      aria-current={isCurrentItem(subItem) ? 'page' : undefined}
                                    >
                                      <ItemPick item={subItem} />
                                      {t(subItem.name)}
                                    </Link>
                                  ))
                                }
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </>
                  ))
                }
              </div>
              {
                isLogged && (
                  <>
                    <div className='pt-4 pb-3 border-t border-gray-700 transition duration-150 ease-in-out'>
                      <div className='flex items-center px-5 justify-between'>
                        <div className='flex items-center'>
                          <div className='flex-shrink-0'>
                            <UserCircleIcon className='h-10 w-10 rounded-full text-white' />
                          </div>
                          <div className='ml-3'>
                            <p className='text-sm font-medium text-white flex-col flex'>
                              <Trans
                                i18nKey={'nav.login.loggedAs'}
                                values={{
                                  name: getEGName(user),
                                }}
                              >
                                You are logged as:
                                <span className={'gradient-text font-bold'}>{' '}{getEGName(user)}</span>
                              </Trans>
                            </p>
                          </div>
                        </div>
                        <LanguagePicker />
                        <div>
                          {
                            darkTheme ? (
                              <button
                                type='button'
                                className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300 hover:scale-110 scale-90')}
                                onClick={() => switchTheme()}
                                aria-label='Switch to light theme'
                              >
                                <SunIcon
                                  className='h-6 w-6 text-white fill-current'
                                  aria-hidden='true'
                                />
                              </button>
                            ) : (
                              <button
                                type='button'
                                className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300  hover:scale-110 scale-90')}
                                onClick={() => switchTheme()}
                                aria-label='Switch to dark theme'
                              >
                                <MoonIcon
                                  className='h-6 w-6 text-white fill-current'
                                  aria-hidden='true'
                                />
                              </button>
                            )
                          }
                        </div>
                      </div>
                      <div className='mt-3 px-2 space-y-1'>
                        <Link
                          onClick={() => manageAccount()}
                          to={'?'}
                          className='block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 transition duration-150 ease-in-out'
                        >
                          {t('nav.login.manage')}
                        </Link>
                        <Link
                          onClick={() => logout()}
                          to={'?'}
                          className='block px-3 py-2 rounded-md text-base font-medium text-red-500 hover:bg-gray-700 hover:text-white transition duration-150 ease-in-out'
                        >
                          {t('nav.login.logout')}
                        </Link>
                      </div>
                    </div>
                  </>
                )
              }
              {
                !isLogged && (
                  <>
                    <div className='pt-4 pb-3 border-t border-gray-700 transition duration-150 ease-in-out'>
                      <div className={'flex flex-col sm:flex-row justify-between'}>
                        <div className='px-5'>
                          <button
                            type='button'
                            className='bg-gray-800 text-white px-1 sm:px-3 py-2 rounded-md text-xs sm:text-sm font-medium flex items-center hover:bg-gray-700 transition ease-in-out duration-300 overflow-ellipsis overflow-hidden whitespace-nowrap'
                            onClick={() => createAccountsLogin()}
                          >
                            <EpicGames
                              className='sm:h-6 sm:w-6 h-5 w-5 mr-2 text-white fill-current'
                              aria-hidden='true'
                            />
                            {t('nav.login.signIn')}
                          </button>
                        </div>
                        <div className={'flex flex-row sm:px-0 px-4 pt-2 sm:pt-0'}>
                          <LanguagePicker />
                          <div className='mr-5'>
                            {
                              darkTheme ? (
                                <button
                                  type='button'
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300 hover:scale-110 scale-90')}
                                  onClick={() => switchTheme()}
                                  aria-label='Switch to light theme'
                                >
                                  <SunIcon
                                    className='h-6 w-6 text-white fill-current'
                                    aria-hidden='true'
                                  />
                                </button>
                              ) : (
                                <button
                                  type='button'
                                  className={classNames('text-white px-3 py-2 rounded-md text-sm font-medium flex items-center transition ease-in-out duration-300  hover:scale-110 scale-90')}
                                  onClick={() => switchTheme()}
                                  aria-label='Switch to dark theme'
                                >
                                  <MoonIcon
                                    className='h-6 w-6 text-white fill-current'
                                    aria-hidden='true'
                                  />
                                </button>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
