import React, { useContext, useEffect, useState } from 'react';
import { AccountsService } from '../accountsService';
import { toast } from 'react-toastify';

export interface IContext {
  avatar: string;
  connections: Record<string, {
    id: string;
    name: string;
    provider: string;
    flags: number;
  }>;
  flags: number;
  id: string;
  last_connection: string;
  name: string;
  name_updated_at: string;
  permissions: number;
  platform: number;
}

export const getEGName = (user: IContext): string => {
  if (!user?.connections) return 'Unknown';
  const epicGamesConnection = Object.values(user.connections).find(connection => connection.provider === 'epicgames');
  return epicGamesConnection ? epicGamesConnection.name : user.name;
};

export enum NavigationItemType {
  Home = 'home',
  Leaderboard = 'leaderboard',
  Tournament = 'tournament',
  Latest = 'latest',
}

export enum NavigationDisplayType {
  Singular = 'singular',
  Dropdown = 'dropdown',
}

export interface NavigationItemBase {
  name: string;
  keep: boolean;
  href?: string;
  type?: NavigationItemType;
}

enum NavigationItemDropdownNoItems {
  Show = 'show',
  Hide = 'hide',
}

export interface NavigationItemSingular extends NavigationItemBase {
  display: NavigationDisplayType.Singular;
  href: string;
  exact: boolean;
}

export interface NavigationItemDropdown extends NavigationItemBase {
  id: string;
  max_items: number;
  no_items: NavigationItemDropdownNoItems;
  display: NavigationDisplayType.Dropdown;
  items: NavigationItemSingular[];
}

export type NavigationItem = NavigationItemSingular | NavigationItemDropdown;

const defaultNavigation: NavigationItem[] = [
  {
    display: NavigationDisplayType.Singular,
    name: 'pages.home',
    href: '/',
    keep: true,
    exact: true,
    type: NavigationItemType.Home,
  },
  {
    display: NavigationDisplayType.Singular,
    name: 'Fishermans Friend Gaming Challenge',
    href: '/fishermansfriend',
    keep: true,
    exact: true,
    type: NavigationItemType.Tournament,
  },
  {
    display: NavigationDisplayType.Dropdown,
    name: 'pages.recentPages',
    type: NavigationItemType.Latest,
    id: 'latest-tabs',
    keep: true,
    max_items: 5,
    no_items: NavigationItemDropdownNoItems.Hide,
    items: [],
  },
];

const MAX_NAVIGATION_ITEMS = 3;

export interface Leaderboard {
  id: string;
  name: string;
  map_code: string;
  type: LeaderboardTypeData;
  enabled: boolean;
  logo_url?: string;
  custom_shorten?: string;
  leaderboard_asc?: boolean;
  lb_settings: LeaderboardLBSettings[];
  score_settings: LeaderboardScoreSettings;
  navbar_settings?: LeaderboardNavbarSettings;
  background_settings?: LeaderboardBackgroundSettings;
  info?: LeaderboardInfo[];
}

export enum LeaderboardTypeData {
  Score = 'score',
  Time = 'time',
}

export interface LeaderboardLBSettings {
  after: Date;
  leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType;
  allow_submit: boolean;
  allow_code: boolean;
  warning?: LeaderboardWarning;
}

export enum LeaderboardLBSettingsLeaderboardShowType {
  All = 'all',
  Self = 'self',
  None = 'none',
}

export interface LeaderboardScoreSettings {
  enabled_type: LeaderboardScoreSettingsEnabledType;
}

export enum LeaderboardScoreSettingsEnabledType {
  All = 'all',
  PlainOnly = 'plain_only',
}

export interface LeaderboardNavbarSettings {
  enabled?: boolean;
  navbar_title?: string;
  navbar_color_bg?: string;
  navbar_image_bg?: string;
  navbar_logo?: string;
  navbar_logo_class?: string;
  navigation_hidden?: boolean;
  navbar_buttons_class?: string;
  navbar_title_redirect_disabled?: boolean;
}

export interface LeaderboardBackgroundSettings {
  enabled?: boolean;
  background_color?: string;
  background_image?: string;
}

export enum LeaderboardInfoHeaderType {
  Header = 'header',
  Text = 'text',
  SubHeader = 'subheader',
  Empty = 'empty',
  Button = 'button',
  Image = 'image',
}

export interface LeaderboardInfoHeader {
  type: LeaderboardInfoHeaderType.Header;
  text: string;
  before?: string;
  after?: string;
  class?: string;
}

export interface LeaderboardInfoText {
  type: LeaderboardInfoHeaderType.Text;
  text: string;
  before?: string;
  after?: string;
  class?: string;
}

export interface LeaderboardInfoSubHeader {
  type: LeaderboardInfoHeaderType.SubHeader;
  text: string;
  class?: string;
  before?: string;
  after?: string;
}

export interface LeaderboardInfoEmpty {
  type: LeaderboardInfoHeaderType.Empty;
  class?: string;
}

export interface LeaderboardInfoButton {
  type: LeaderboardInfoHeaderType.Button;
  text: string;
  href: string;
  new_tab?: boolean;
  class?: string;
}

export interface LeaderboardInfoImage {
  type: LeaderboardInfoHeaderType.Image;
  src: string;
  alt: string;
  class?: string;
}

export type LeaderboardInfo =
  LeaderboardInfoHeader
  | LeaderboardInfoText
  | LeaderboardInfoSubHeader
  | LeaderboardInfoEmpty
  | LeaderboardInfoButton
  | LeaderboardInfoImage;

export interface LeaderboardWarning {
  enabled: boolean;
  text: string;
}

export const leaderboards: Leaderboard[] = [
  {
    id: '600247955701653504',
    name: 'JUMBO GAME Open Tournament',
    type: LeaderboardTypeData.Score,
    enabled: true,
    map_code: '8776-4811-8043',
    logo_url: '/images/custom_navbar_logo.png',
    custom_shorten: 'jumbo',
    score_settings: {
      enabled_type: LeaderboardScoreSettingsEnabledType.PlainOnly,
    },
    lb_settings: [
      {
        after: new Date(Date.UTC(2024, 6, 10, 0, 0, 0)), // 10/07/2024 02:00:00 CEST
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.None,
        allow_submit: false,
        allow_code: false,
      },
      {
        after: new Date(Date.UTC(2024, 6, 13, 8, 0, 0)), // 13/07/2024 10:00:00 CEST
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.None,
        allow_submit: false,
        allow_code: true,
      },
      {
        after: new Date(Date.UTC(2024, 6, 13, 13, 0, 0)), // 13/07/2024 15:00:00 CEST
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.Self,
        allow_submit: true,
        allow_code: true,
      },
      {
        after: new Date(Date.UTC(2024, 6, 14, 16, 0, 0)), // 14/07/2024 18:00:00 CEST
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.All,
        allow_submit: true,
        allow_code: true,
        warning: {
          text: 'leaderboard.tournaments.jumbo0724.warning',
          enabled: true,
        },
      },
      {
        after: new Date(Date.UTC(2024, 6, 14, 19, 0, 0)), // 14/07/2024 21:00:00 CEST
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.All,
        allow_submit: false,
        allow_code: true,
      },
    ],
    navbar_settings: {
      enabled: true,
      navbar_title: 'JUMBO GAME Open Tournament',
      navbar_logo: 'custom_navbar_logo.png',
      navbar_image_bg: 'custom_navbar_image_bg.png',
      navbar_logo_class: 'rounded-full',
      navigation_hidden: true,
      navbar_buttons_class: 'bg-[#1e3347] hover:bg-[#2e4b68]',
      navbar_title_redirect_disabled: true,
    },
    info: [
      {
        type: LeaderboardInfoHeaderType.Header,
        text: '🏆 JUMBO GAME Tournament',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.0',
        class: 'font-bold',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.1',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Header,
        text: 'leaderboard.tournaments.jumbo0724.2',
        before: '📅',
      },
      {
        type: LeaderboardInfoHeaderType.SubHeader,
        text: 'leaderboard.tournaments.jumbo0724.3',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.4',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.5',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.6',
        class: 'font-bold',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.7',
        after: '🏆',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.8',
        class: 'font-bold',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.9',
        after: '🏆',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.SubHeader,
        text: 'leaderboard.tournaments.jumbo0724.10',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.11',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.12',
        class: 'italic',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.jumbo0724.13',
        class: 'italic',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Button,
        text: 'leaderboard.tournaments.jumbo0724.14',
        href: 'https://twitter.com/SqueezieTV/status/1811120380767260990',
        new_tab: true,
        class: 'bg-[#f7f7f7] text-[#1e3347] hover:bg-[#e5e5e5] hover:text-[#1e3347]',
      },
    ],
  },
  {
    id: '627149054798733312',
    type: LeaderboardTypeData.Time,
    name: 'Fishermans Friend Gaming Challenge',
    enabled: true,
    map_code: '4927-2085-7858',
    leaderboard_asc: true,
    custom_shorten: 'fishermansfriend',
    score_settings: {
      enabled_type: LeaderboardScoreSettingsEnabledType.PlainOnly,
    },
    lb_settings: [
      {
        // 29/09/2024 00:00:00 CEST
        after: new Date(Date.UTC(2024, 8, 28, 22, 0, 0)),
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.All,
        allow_submit: true,
        allow_code: true,
      },
      {
        after: new Date(Date.UTC(2024, 10, 3, 21, 59, 59)),
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.All,
        allow_submit: false,
        allow_code: true,
      },
    ],
    background_settings: {
      enabled: true,
      background_image: '/images/fishermans_friend_gaming_challenge_bg.png',
    },
    info: [
      {
        type: LeaderboardInfoHeaderType.Header,
        text: '🏆 Fishermans Friend Gaming Challenge',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.8',
      },
      {
        type: LeaderboardInfoHeaderType.Image,
        src: '/images/fishermans_friend_gaming_challenge_logo.png',
        alt: 'Fishermans Friend Gaming Challenge',
        class: 'w-1/2 mx-auto max-h-64',
      },
      {
        type: LeaderboardInfoHeaderType.SubHeader,
        text: 'leaderboard.tournaments.fish0924.14',
        before: '📅',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.9',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.10',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.SubHeader,
        text: 'leaderboard.tournaments.fish0924.15',
        before: '🥇',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.0',
        class: 'font-bold',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.3',
        after: '🎮',
        before: '🎮',
        class: 'font-bold dark:text-[#f7f7f7] dark:bg-[#1e3347] px-2 py-1 rounded-full text-sm bg-[#3aa0e2] text-white',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.4',
        class: 'font-bold',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Button,
        text: 'leaderboard.tournaments.fish0924.16',
        href: 'https://drive.google.com/file/d/1FWxNU5LnIXOjLixicOUSOz_p5_m22G1x/preview',
        new_tab: true,
        class: 'bg-[#f7f7f7] text-[#1e3347] hover:bg-[#e5e5e5] hover:text-[#1e3347]',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.12',
        class: 'italic',
      },
      {
        type: LeaderboardInfoHeaderType.Text,
        text: 'leaderboard.tournaments.fish0924.13',
        class: 'italic',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
      {
        type: LeaderboardInfoHeaderType.Button,
        text: 'leaderboard.tournaments.fish0924.11',
        href: 'https://docs.google.com/document/d/1Erj95Gi4nT50IDcNb7sIJJhW8ykuy9bD/',
        new_tab: true,
        class: 'bg-[#f7f7f7] text-[#1e3347] hover:bg-[#e5e5e5] hover:text-[#1e3347]',
      },
      {
        type: LeaderboardInfoHeaderType.Empty,
      },
    ],
  },
  {
    id: '614474844003639296',
    name: 'WORLD ZONE WARS',
    custom_shorten: 'worldzw',
    type: LeaderboardTypeData.Score,
    enabled: true,
    logo_url: '/images/world_zone_wars_logo.png',
    navbar_settings: {
      enabled: true,
    },
    map_code: '2598-0396-1991',
    lb_settings: [
      {
        after: new Date(Date.UTC(2024, 6, 10, 0, 0, 0)), // 10/07/2024 02:00:00 CEST
        leaderboard_show_type: LeaderboardLBSettingsLeaderboardShowType.All,
        allow_submit: true,
        allow_code: true,
      },
    ],
    score_settings: {
      enabled_type: LeaderboardScoreSettingsEnabledType.All,
    },
    info: [
      {
        type: LeaderboardInfoHeaderType.Header,
        text: '🏆 WORLD ZONE WARS',
      },
      {
        type: LeaderboardInfoHeaderType.Image,
        src: '/images/world_zone_wars_logo.png',
        alt: 'WORLD ZONE WARS',
        class: 'w-1/2 mx-auto max-h-64',
      },
    ],
  }
];

export interface CustomRedirect {
  enabled: boolean;
  from: string;
  to: string;
}

export const customRedirects: CustomRedirect[] = [
  {
    enabled: true,
    from: '/lb/598177142190149632',
    to: '/jumbo',
  },
  {
    enabled: true,
    from: '/zoneworld',
    to: '/worldzw',
  },
  {
    enabled: true,
    from: '/leaderboard/598177142190149632',
    to: '/jumbo',
  },
];

export const UserContext = React.createContext<{
  isLoading: boolean;
  isLogged: boolean;
  user: IContext;
  navigation: NavigationItem[];
  addNavigationItem: (item: NavigationItem) => void;
  setNavigationItem: (item: NavigationItem) => void;
  removeNavigationItem: (name: string) => void;
  removeNavigationItemHref: (href: string) => void;
  addDropdownItem: (id: string, item: NavigationItemSingular) => void;
  removeDropdownItem: (id: string, href: string) => void;
  setDropdownItem: (id: string, item: NavigationItemSingular) => void;
  setUser: React.Dispatch<React.SetStateAction<IContext>>;
  fetchUser(): Promise<void>;
  currentLeaderboard: Leaderboard | null;
  setCLeaderboard(leaderboardId: string | undefined): Leaderboard | null;
  removeCLeaderboard(): void;
  darkTheme: boolean;
  switchTheme(): void;
}>({
  isLoading: false,
  isLogged: false,
  user: {} as IContext,
  navigation: [] as NavigationItem[],
  addNavigationItem: () => null,
  setNavigationItem: () => null,
  removeNavigationItem: () => null,
  removeNavigationItemHref: () => null,
  addDropdownItem: () => null,
  removeDropdownItem: () => null,
  setDropdownItem: () => null,
  setUser: () => null,
  fetchUser: () => Promise.resolve(),
  currentLeaderboard: null,
  setCLeaderboard: () => null,
  removeCLeaderboard: () => null,
  darkTheme: false,
  switchTheme: () => null,
});

export const UserProvider = ({
                               children,
                             }: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<IContext>({} as IContext);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [navigation, setNavigation] = useState<NavigationItem[]>(defaultNavigation);
  const [currentLeaderboard, setCurrentLeaderboard] = useState<Leaderboard | null>(null);
  const [darkTheme, setDarkTheme] = useState<boolean>(false);

  const fetchUser = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('WCA-TOKEN');
    if (!token) {
      setIsLogged(false);
      setUser({} as IContext);
      setIsLoading(false);
      return;
    }
    const response = await AccountsService.me(token)
      .catch((error) => {
        toast.error(`You have been logged out. ${error?.message ?? 'Something went wrong'}`);
        localStorage.removeItem('WCA-TOKEN');
        return {} as IContext;
      });
    setUser(response);
    setIsLogged(true);
    setIsLoading(false);
  };

  const addNavigationItem = (item: NavigationItem) => {
    if (navigation.find((navItem) => {
      if (navItem.display === NavigationDisplayType.Singular) {
        return item.display === NavigationDisplayType.Singular && navItem.href === item.href;
      } else {
        return item.display === NavigationDisplayType.Dropdown && navItem.id === item.id;
      }
    })) {
      return;
    }
    const nonKeepItems = navigation.filter((item) => !item.keep);
    const keepItems = navigation.filter((item) => item.keep);
    if (nonKeepItems.length >= MAX_NAVIGATION_ITEMS) {
      setNavigation([...keepItems, ...nonKeepItems.slice(nonKeepItems.length - MAX_NAVIGATION_ITEMS + 1), item]);
    } else {
      setNavigation([...keepItems, ...nonKeepItems, item]);
    }
  };

  const setNavigationItem = (item: NavigationItem) => {
    const nonKeepItems = navigation.filter((item) => !item.keep);
    const keepItems = navigation.filter((item) => item.keep);
    const index = nonKeepItems.findIndex((navItem) => {
      if (navItem.display === NavigationDisplayType.Singular) {
        return item.display === NavigationDisplayType.Singular && navItem.href === item.href;
      } else {
        return item.display === NavigationDisplayType.Dropdown && navItem.id === item.id;
      }
    });
    if (index === -1) {
      if (nonKeepItems.length >= MAX_NAVIGATION_ITEMS) {
        setNavigation([...keepItems, ...nonKeepItems.slice(nonKeepItems.length - MAX_NAVIGATION_ITEMS + 1), item]);
      } else {
        setNavigation([...keepItems, ...nonKeepItems, item]);
      }
    } else {
      nonKeepItems[index] = item;
      setNavigation([...keepItems, ...nonKeepItems]);
    }
  };

  const removeNavigationItem = (name: string) => {
    setNavigation(navigation.filter((item) => item.name !== name));
  };

  const removeNavigationItemHref = (href: string) => {
    setNavigation(navigation.filter((item) => {
      if (item.display === NavigationDisplayType.Singular) {
        return item.href !== href;
      } else {
        return !item.items.find((subItem) => subItem.href === href);
      }
    }));
  };

  const addDropdownItem = (id: string, item: NavigationItemSingular) => {
    const dropdownItem = navigation.find((navItem) => navItem.display === NavigationDisplayType.Dropdown && navItem.id === id) as NavigationItemDropdown;
    if (dropdownItem.items.find((subItem) => subItem.href === item.href)) {
      return;
    }
    if (dropdownItem.items.length >= dropdownItem.max_items) {
      dropdownItem.items.pop();
    }
    dropdownItem.items.unshift(item);
    setNavigation([...navigation]);
  };

  const removeDropdownItem = (id: string, href: string) => {
    const dropdownItem = navigation.find((navItem) => navItem.display === NavigationDisplayType.Dropdown && navItem.id === id) as NavigationItemDropdown;
    dropdownItem.items = dropdownItem.items.filter((item) => item.href !== href);
    setNavigation([...navigation]);
  };

  const setDropdownItem = (id: string, item: NavigationItemSingular) => {
    const dropdownItem = navigation.find((navItem) => navItem.display === NavigationDisplayType.Dropdown && navItem.id === id) as NavigationItemDropdown;
    const index = dropdownItem.items.findIndex((subItem) => subItem.href === item.href);
    if (index === -1) {
      if (dropdownItem.items.length >= dropdownItem.max_items) {
        dropdownItem.items.pop();
      }
      dropdownItem.items.unshift(item);
    } else {
      dropdownItem.items[index] = item;
    }
    setNavigation([...navigation]);
  };

  const setLeaderboard = (leaderboardId: string): Leaderboard | null => {
    const leaderboard = leaderboards.find((leaderboard) => leaderboard.id === leaderboardId);
    if (leaderboard) {
      setCurrentLeaderboard(leaderboard);
      return leaderboard;
    } else {
      setCurrentLeaderboard(null);
      return null;
    }
  };

  const removeLeaderboard = () => {
    setCurrentLeaderboard(null);
  };

  useEffect(() => {
    loadTheme();
    fetchUser()
      .then(() => null);
  }, []);

  const switchTheme = () => {
    setDarkTheme(!darkTheme);

    const root = window.document.getElementById('root');
    const html = window.document.documentElement;
    if (!root) {
      return;
    }
    root.classList.toggle('dark');
    html.classList.toggle('dark');
    localStorage.setItem('darkTheme', darkTheme ? 'false' : 'true');
  };

  const setTheme = (dark: boolean) => {
    setDarkTheme(dark);

    const root = window.document.getElementById('root');
    const html = window.document.documentElement;
    if (!root) {
      return;
    }
    if (dark) {
      root.classList.add('dark');
      html.classList.add('dark');
    } else {
      root.classList.remove('dark');
      html.classList.remove('dark');
    }
  };

  const loadTheme = () => {
    const dark = localStorage.getItem('darkTheme');
    if (dark) {
      setTheme(dark === 'true');
    } else {
      const darkOS = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setTheme(darkOS);
    }
  };

  return (
    <UserContext.Provider
      value={{
        isLogged,
        user,
        navigation,
        isLoading,
        setUser,
        fetchUser,
        addNavigationItem,
        setNavigationItem,
        removeNavigationItem,
        removeNavigationItemHref,
        addDropdownItem,
        removeDropdownItem,
        setDropdownItem,
        currentLeaderboard,
        setCLeaderboard: setLeaderboard,
        removeCLeaderboard: removeLeaderboard,
        darkTheme,
        switchTheme,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
