import React from 'react';
import { AtSymbolIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export function ContactButton({
                                input = 'Contact Us',
                                onClick,
                                href,
                              }: {
  input?: string,
  onClick?: () => void,
  href?: string,
}) {
  return (
    <>
      {href ? (
        <Link
          to={href}
          rel='noopener noreferrer'
          className={'text-white font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center mb-2' +
            ' scale-100 transition-transform duration-300 ease-in-out cursor-pointer hover:bg-opacity-80 hover:shadow-lg hover:scale-105 bg-gradient-to-r from-[#f34a53] to-[#cb3c6d]'}>
          <AtSymbolIcon className='w-4 h-4 mr-2 -ml-1' />
          {input}
        </Link>
      ) : (
        <button type='button'
                onClick={onClick}
                className={'text-white font-medium rounded-lg text-sm md:px-5 px-3 py-2.5 text-center inline-flex items-center mb-2' +
                  ' scale-100 transition-transform duration-300 ease-in-out cursor-pointer hover:bg-opacity-80 hover:shadow-lg hover:scale-105 bg-gradient-to-r from-[#f34a53] to-[#cb3c6d]'}>
          <AtSymbolIcon className='w-4 h-4 mr-2 -ml-1' />
          {input}
        </button>
      )}
    </>
  );
}
