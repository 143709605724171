import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useUser } from '../utils/context/userContext';
import { useTranslation } from 'react-i18next';

export default function _404() {
  const { t } = useTranslation();
  const { removeCLeaderboard } = useUser();
  useEffect(() => {
    removeCLeaderboard();
  }, []);
  return (
    <>
      <Helmet>
        <title>404 | FortniteRanking.com</title>
        <meta name={'description'} content={'404 | FortniteRanking.com'} />
      </Helmet>
      <div className={'Main w-screen h-[calc(100vh-7.5rem)]'}>
        <div className={'flex flex-col items-center pt-10'}>
          <article
            className={'h-full w-full bg-white rounded-lg shadow-md p-4 items-center justify-center text-center flex flex-col space-y-2 dark:bg-gray-800'}
          >
            <h1 className={'text-3xl font-extrabold'}>{t('404.title')}</h1>
            <p>
              {t('404.description')}
            </p>
          </article>
        </div>
      </div>
    </>
  );
}
