import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../utils/context/userContext';
import { classNames } from '../utils/helpers';

export default function Footer() {
  const { t } = useTranslation();
  const { currentLeaderboard } = useUser();
  return (<>
    <div
      className={classNames(
        'Footer text-center',
        currentLeaderboard?.background_settings?.background_image ? 'text-gray-800 font-bold' : 'bg-gray-100 dark:bg-gray-900 dark:text-gray-200',
      )}
    >
      <div className={'Footer__container'}>
        <h5 className={'Footer__container__title'}>
          © {new Date().getFullYear()} - War Legend
        </h5>
        <span className={'Footer__container__title max-w-7xl text-center text-sm'}>
          {t('footer.description')}
        </span>
        <h5 className={'Footer__container__links'}>
          <Link to={'/legal'}
                onClick={() => window.scrollTo(0, 0)}>
            {t('pages.legal')}
          </Link>
          {' | '}
          <Link to={'/privacy-policy'}
                onClick={() => window.scrollTo(0, 0)}>
            {t('pages.privacy')}
          </Link>
          {' | '}
          <Link to={'/contact'}
                onClick={() => window.scrollTo(0, 0)}>
            {t('pages.contact')}
          </Link>
        </h5>
      </div>
    </div>
  </>);
}
