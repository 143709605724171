import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { NavigationDisplayType, useUser } from '../utils/context/userContext';

export default function Legal() {
  const { setDropdownItem } = useUser();
  const { removeCLeaderboard } = useUser();
  useEffect(() => {
    removeCLeaderboard();
  }, []);
  useEffect(() => {
    setDropdownItem('latest-tabs', {
      display: NavigationDisplayType.Singular,
      name: 'pages.legal',
      href: `/legal`,
      keep: false,
      exact: true,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Legal Notice | FortniteRanking.com</title>
        <meta name={'description'} content={'Legal Notice | FortniteRanking.com'} />
      </Helmet>
      <div className={'Main w-screen'}>
        <div className={'flex flex-col items-center pt-10'}>
          <article
            className={'prose h-full w-full max-w-6xl md:prose-lg prose-md bg-white rounded-lg shadow-md p-4 dark:bg-gray-800 dark:text-gray-300'}>
            <h1 className='text-4xl dark:text-gray-100' id='h.4m2m82iafqg4'><span className='c2'>1. Presentation of the website.</span></h1>
            <p className='c1'><span className='c0'>Pursuant to Article 6 of Law No. 2004-575 of 21 June 2004 on confidence in the digital economy, users of the FortniteRanking.com website are informed of the identity of the various parties involved in its creation and monitoring:</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c6'>Owner</span><span className='c0'>: WAR LEGEND - SAS 89802533300011 - 82 boulevard de grenelle 75015 PARIS - FRANCE</span>
            </p>
            <p className='c1'><span className='c6'>Creator</span><span className='c0'>: WAR LEGEND - SAS 89802533300011 - 82 boulevard de grenelle 75015 PARIS - FRANCE</span>
            </p>
            <p className='c1'><span className='c6'>Publication manager</span><span className='c0'>: ROBIN ALEMAN - <a
              className='c7 dark:text-gray-300' href='mailto:crazz@warlegend.gg'>crazz@warlegend.gg</a></span>
            </p>
            <p className='c1'><span className='c0'>The person responsible for publication is an individual or a legal entity.</span>
            </p>
            <p className='c1'><span className='c6'>Webmaster</span><span className='c0'>: R&eacute;mi Langdorph - <a
              className='c7 dark:text-gray-300'
              href='mailto:mrerl@warlegend.gg'>mrerl@warlegend.gg</a></span>
            </p>
            <p className='c1'><span className='c6'>Host</span><span className='c0'>: OVH SAS - 2, rue Kellermann, 59100 Roubaix, France</span>
            </p>
            <p className='c1'><span className='c6'>Credits</span><span>: <br />R&eacute;mi Langdorph - </span><span
              className='c9'><a className='c7 dark:text-gray-300'
                                href='mailto:mrerl@warlegend.gg'>mrerl@warlegend.gg</a></span><span>,<br />Adam Kalinowski </span><span
              className='c9'><a className='c7 dark:text-gray-300' href='mailto:iamsushi@warlegend.gg'>iamsushi@warlegend.gg</a></span><span
              className='c0'>.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.gpt5fusk6p2x'><span className='c2'>2. General terms and conditions of use of the site and the services offered.</span>
            </h1>
            <p className='c1'><span
              className='c0'>The legal notice model is offered by FortniteRanking.com</span></p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>This site is normally accessible to users at all times. WAR LEGEND may, however, decide to interrupt the site for technical maintenance purposes, in which case it will endeavour to inform users of the dates and times of the intervention in advance.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>The FortniteRanking.com website is regularly updated by ROBIN ALEMAN. Similarly, the legal notices may be modified at any time: they are nevertheless binding on the user, who is invited to refer to them as often as possible in order to read them.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.g0e4euzg7kd2'><span className='c2'>3. Description of the services provided.</span>
            </h1>
            <p className='c1'><span className='c0'>The purpose of the FortniteRanking.com website is to provide information about all of the company&#39;s activities.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>WAR LEGEND endeavours to provide information on the FortniteRanking.com website that is as accurate as possible. However, WAR LEGEND cannot be held responsible for any omissions, inaccuracies or failure to update such information, whether caused by WAR LEGEND or by third-party partners who provide such information.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>All the information provided on the FortniteRanking.com website is not exhaustive. It is given subject to modifications having been made since it was put on line.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.4xk4vzfji6t0'><span
              className='c2'>4. Contractual limitations on technical data.</span></h1>
            <p className='c1'><span className='c0'>The website uses JavaScript technology.</span></p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>The website cannot be held responsible for any material damage linked to the use of the site. Furthermore, the user of the site undertakes to access the site using recent, virus-free equipment and with an up-to-date, latest-generation browser.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.ifemrrjf3i1r'><span
              className='c2'>5. Intellectual property and counterfeiting.</span></h1>
            <p className='c1'><span className='c0'>WAR LEGEND is the owner of the intellectual property rights or holds the rights of use on all the elements accessible on the site, in particular the texts, images, graphics, logos, icons, sounds and software.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Fortnite Ranking uses proprietary graphics of Epic Games, Inc. Epic, Epic Games, the Epic Games logo, Fortnite, the Fortnite logo, Unreal, Unreal Engine 4 and UE4 are trademarks or registered trademarks of Epic Games, Inc. in the United States of America and elsewhere.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Fortnite Ranking uses proprietary graphics of War Legend. The War Legend logo are trademarks or registered trademarks of War Legend in Europe and elsewhere.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, whatever the means or process used, is prohibited, except with the prior written authorisation of WAR LEGEND.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Any unauthorised use of the site or of any of the elements it contains will be considered as constituting an infringement and will be prosecuted in accordance with the provisions of articles L.335-2 et seq. of the French Intellectual Property Code.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.mbxxvjwb7s7o'><span className='c2'>6. Limitation of liability.</span></h1>
            <p className='c1'><span className='c0'>WAR LEGEND cannot be held responsible for direct or indirect damage caused to the user&#39;s equipment when accessing the FortniteRanking.com website, and resulting either from the use of equipment that does not meet the specifications indicated in point 4, or from the appearance of a bug or incompatibility.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>WAR LEGEND cannot be held responsible for indirect damage (such as loss of business or loss of opportunity) resulting from the use of the FortniteRanking.com website.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Interactive areas (possibility to ask questions in the contact area) are available to users. WAR LEGEND reserves the right to delete, without prior notice, any content posted in this area that contravenes the legislation applicable in France, in particular provisions relating to data protection. Where applicable, WAR LEGEND also reserves the right to hold the user civilly and/or criminally liable, particularly in the event of messages of a racist, insulting, defamatory or pornographic nature, whatever the medium used (text, photographs, etc.).</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.ixm4swor4qn9'><span className='c2'>7. Management of personal data.</span></h1>
            <p className='c1'><span className='c0'>In France, personal data is protected by law no. 78-87 of 6 January 1978, law no. 2004-801 of 6 August 2004, article L. 226-13 of the Criminal Code and the European Directive of 24 October 1995.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>When using the FortniteRanking.com website, the following information may be collected: the URL of the links via which the user has accessed the FortniteRanking.com website, the name of the website from which the user has accessed the FortniteRanking.com website and the name of the website from which the user has accessed the FortniteRanking.com website.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>In accordance with the provisions of Articles 38 et seq. of Law 78-17 of 6 January 1978 on Data Processing, Data Files and Individual Liberties, all users have the right to access, rectify and object to any personal data concerning them, by making a written and signed request, accompanied by a copy of the identity document bearing the signature of the holder of the document, and specifying the address to which the reply is to be sent.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>No personal information of the user of the FortniteRanking.com website is published without the user&#39;s knowledge, exchanged, transferred, ceded or sold on any medium whatsoever to third parties. Only the assumption of the purchase of WAR LEGEND and its rights would allow the transmission of the said information to the eventual purchaser who would in turn be bound by the same obligation of conservation and modification of the data with respect to the user of the FortniteRanking.com website.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span>The databases are protected by the provisions of the law of 1 July 1998 transposing directive 96/9 of 11 March 1996 on the legal protection of databases.<br /><br /></span><span>Please consult our </span><span
              className='c9'><a className='c7 dark:text-gray-300'
                                href='/privacy-policy'>Privacy Policy</a></span><span>&nbsp;page for extended information.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <h1 className='text-4xl dark:text-gray-100' id='h.p5hdpb1cwcf5'><span className='c2'>8. Hypertext links and cookies.</span></h1>
            <p className='c1'><span className='c0'>The FortniteRanking.com site contains a number of hypertext links to other sites, set up with the authorisation of WAR LEGEND. However, WAR LEGEND is not able to check the content of these sites, and consequently cannot accept any responsibility for them.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Browsing the FortniteRanking.com website may result in the installation of cookie(s) on the user&#39;s computer. A cookie is a small file which does not allow the user to be identified, but which records information relating to the browsing of a computer on a site. The data obtained in this way is intended to facilitate subsequent browsing of the site and is also used to measure visitor numbers.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Refusal to install a cookie may make it impossible to access certain services.</span>
            </p>
            <h1 className='text-4xl dark:text-gray-100' id='h.wrvr5sfgbibf'><span className='c2'>9. Applicable law and jurisdiction.</span></h1>
            <p className='c1'><span className='c0'>Any dispute relating to the use of the FortniteRanking.com website is subject to French law. Exclusive jurisdiction is granted to the competent courts of Paris.</span>
            </p>
            <h1 className='text-4xl dark:text-gray-100' id='h.h1rrvtr2dugx'><span className='c2'>10. The main laws concerned.</span></h1>
            <p className='c1'><span className='c0'>Law no. 78-17 of 6 January 1978, as amended by law no. 2004-801 of 6 August 2004 relating to information technology, files and civil liberties.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Law no. 2004-575 of 21 June 2004 on confidence in the digital economy.</span>
            </p>
            <h1 className='text-4xl dark:text-gray-100' id='h.rkekbzuuj3xf'><span className='c2'>11. Glossary.</span></h1>
            <p className='c1'><span
              className='c0'>User: Internet user connecting to and using the aforementioned site.</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1'><span className='c0'>Personal information: &quot;information which enables, in any form whatsoever, directly or indirectly, the identification of the natural persons to whom it applies&quot; (article 4 of law no. 78-17 of 6 January 1978).</span>
            </p>
            <p className='c1 c4'><span className='c0'></span></p>
            <p className='c1 c4'><span className='c0'></span></p>
          </article>
        </div>
      </div>
    </>
  );
}
