import React from 'react';

export default function LogoIcon({
                              className,
                                   src,
                            }: {
  className?: string;
  src?: string;
}) {
  return (
    <img
      src={src ? src : '/logo.png'}
      alt='Webiste Logo'
      className={className}
    />
  );
}
