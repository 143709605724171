import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { NavigationDisplayType, useUser } from '../utils/context/userContext';

export default function Contact() {
  const { setDropdownItem } = useUser();
  const { removeCLeaderboard } = useUser();
  useEffect(() => {
    removeCLeaderboard();
  }, []);
  useEffect(() => {
    setDropdownItem('latest-tabs', {
      display: NavigationDisplayType.Singular,
      name: 'pages.contact',
      href: `/contact`,
      keep: false,
      exact: true,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact | FortniteRanking.com</title>
        <meta name={'description'} content={'Contact | FortniteRanking.com'} />
      </Helmet>
      <div className={'Main w-screen min-h-[calc(100vh-10rem)]'}>
        <div className={'flex flex-col items-center pt-10'}>
          <article className={'h-full w-full max-w-6xl bg-white rounded-lg shadow-md p-4 dark:bg-gray-800'}>
            <h2 className={'font-extrabold text-4xl'}>Fortnite Ranking | Contact</h2>
            <br />
            <b>Fortnite Ranking (FortniteRanking.com) is a service published and created by War Legend.</b><br />
            War Legend is a company specialised in Fortnite competition organisation, Fortnite related software
            development and Fortnite reverse engineering since 2018.<br />
            <br />
            Are you interested in getting this service for your own products? Contact us at
            {' '}
            <Link to={'mailto:contact@warlegend.gg'} className={'dark:text-gray-100 underline'}>
              contact@warlegend.gg
            </Link>
            <br />
            <br />
            If you are a representative of Epic Games and wish to get in touch with our management please contact
            directly Robin Aleman (Directeur Général):
            {' '}
            <Link to={'mailto:crazz@warlegend.gg'} className={'dark:text-gray-100 underline'}>
              crazz@warlegend.gg
            </Link>
          </article>
        </div>
      </div>
    </>
  );
}
