import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import ApiAuth from './routes/auth';
import Policy from './routes/policy';
import Legal from './routes/legal';
import _404 from './routes/_404';
import Contact from './routes/contact';
import Main from './routes/main';
import Leaderboard from './routes/leaderboard';
import { customRedirects, leaderboards } from './utils/context/userContext';

export function RouterImplementation() {
  return (
    <Routes>
      {/* Custom Redirects */}
      {
        customRedirects.filter((rd) => rd.enabled).map((rd, index) => (
          <Route key={index} path={rd.from} element={<Navigate to={rd.to} replace={true} />} />
        ))
      }

      <Route path={'/'} element={<Main />} />
      <Route path={'/leaderboard/:id'} element={<Leaderboard />} />
      <Route path={'/lb/:id'} element={<Leaderboard />} />
      <Route path={'/auth/callback'} element={<ApiAuth />} />

      {/* Generated LB Routes */}
      {
        leaderboards.filter((lb) => lb.custom_shorten).map((lb) => (
          <Route key={lb.id} path={`/${lb.custom_shorten}`} element={<Leaderboard custom_id={lb.id} />} />
        ))
      }

      {/*  Static Routes */}
      <Route path={'/privacy-policy'} element={<Policy />} />
      <Route path={'/legal'} element={<Legal />} />
      <Route path={'/contact'} element={<Contact />} />

      {/*  Error Routes */}
      <Route path={'*'} element={<_404 />} />
    </Routes>
  );
}
