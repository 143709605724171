import React from 'react';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from '../utils/context/userContext';
import Footer from './footer';
import ThemeProvider from './themeProvider';

export default function Root({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className={'Root'}>
        <UserProvider>
          <ToastContainer
            position={'bottom-right'}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            style={{
              fontFamily: 'Poppins, sans-serif',
            }}
          />
          <ThemeProvider>
            <Navigation />
            {children}
            <Footer />
          </ThemeProvider>
        </UserProvider>
      </div>
    </>
  );
}
