import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Leaderboard as LeaderboardType } from '../interfaces/leaderboard';
import api from '../utils/api';
import { Helmet } from 'react-helmet';
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowUpTrayIcon,
  ClipboardIcon,
  CursorArrowRaysIcon,
  ExclamationTriangleIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '../utils/helpers';
import {
  Leaderboard as LeaderboardInfoType,
  LeaderboardInfoHeaderType,
  LeaderboardLBSettings,
  LeaderboardLBSettingsLeaderboardShowType,
  LeaderboardScoreSettingsEnabledType,
  LeaderboardTypeData,
  NavigationDisplayType,
  NavigationItemType,
  useUser,
} from '../utils/context/userContext';
import { toast } from 'react-toastify';
import { Transition } from '@headlessui/react';
import { useDropzone } from 'react-dropzone';
import { createWorker } from 'tesseract.js';
import { Trans, useTranslation } from 'react-i18next';
import { useCountdown } from '../utils/useCountdown';

export default function Leaderboard({
                                      custom_id,
                                    }: {
  custom_id?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogged, setCLeaderboard, currentLeaderboard, user, setDropdownItem, navigation } = useUser();
  const { id } = useParams();
  const [allowFetchCode, setAllowFetchCode] = useState<boolean | null>(null);
  const [leaderboard, setLeaderboard] = useState<LeaderboardType | null>(null);
  const [isLeaderboardInfoOpen, setIsLeaderboardInfoOpen] = useState<boolean>(true);
  const [leaderboardNotFound, setLeaderboardNotFound] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<number | null>(null);
  const [isAboveScroll, setIsAboveScroll] = useState<boolean>(false);
  const [waitForLogin, setWaitForLogin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isScoreModalOpen, setIsScoreModalOpen] = useState<boolean>(false);
  const [playerCode, setPlayerCode] = useState<string | boolean | null>(true);
  const [currentLBSettings, setCurrentLBSettings] = useState<LeaderboardLBSettings | null>(null);
  const [nextLBSettings, setNextLBSettings] = useState<LeaderboardLBSettings | null>(null);
  const canAddScore = useRef<boolean>(false);
  const [startDate, setStartDate] = useState<number | null>(null);
  const isAlreadyReading = useRef<boolean>(false);
  const [submitData, setSubmitData] = useState<{
    score: string;
    Mm: string;
    Ss: string;
    d: string;
    verificationCode: string;
    loading: boolean;
  }>({
    score: '',
    Mm: '',
    Ss: '',
    d: '',
    verificationCode: '',
    loading: false,
  });
  const isLeaderboard = !isLoading && leaderboard !== null;
  const isLoadingWithLeaderboard = isLoading && leaderboard === null;
  const entry = selectedEntry !== null ? (leaderboard?.teams[selectedEntry] ?? null) : null;

  const currentAndNextLBSettings = (currLb: LeaderboardInfoType, time: number | null): [LeaderboardLBSettings | null, LeaderboardLBSettings | null] => {
    if (!currLb.lb_settings) return [null, null];
    const now = time ? new Date(time * 1000) : new Date();
    setStartDate(now.getTime());
    const settings = currLb.lb_settings;
    const settingsFromLatest = [...settings].sort((a, b) => b.after.getTime() - a.after.getTime());
    const settingsFromEarliest = [...settings].sort((a, b) => a.after.getTime() - b.after.getTime());
    const current = settingsFromLatest.find((setting) => setting.after.getTime() < now.getTime()) ?? null;
    const next = settingsFromEarliest.find((setting) => setting.after.getTime() > now.getTime()) ?? null;

    return [current, next];
  };

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    if (!canAddScore?.current) {
      toast.error(t('leaderboard.wait.codeDisabled'));
      return;
    }
    if (isAlreadyReading.current) return;
    isAlreadyReading.current = true;
    if (acceptedFiles.length === 0) {
      isAlreadyReading.current = false;
      return;
    }
    const image = URL.createObjectURL(acceptedFiles[0]);
    const worker = await createWorker(['eng']);
    await worker.setParameters({
      tessedit_char_whitelist: '0123456789',
      tessedit_char_blacklist: ' ',
    });
    toast.info(t('leaderboard.reader.info'));
    const { data } = await worker.recognize(image);
    const { lines } = data;
    let best1: { text: string, confidence: number, raw: any } = { text: '', confidence: 0, raw: null };
    let best2: { text: string, confidence: number, raw: any } = { text: '', confidence: 0, raw: null };
    lines.forEach((line) => {
      const text = line.text.replace(/[^a-zA-Z0-9]/g, '');
      if (line.confidence > best1.confidence) {
        best2 = best1;
        best1 = { text, confidence: line.confidence, raw: line };
      } else if (line.confidence > best2.confidence) {
        best2 = { text, confidence: line.confidence, raw: line };
      }
    });

    if (best1.raw?.words?.length > 1) {
      best1 = best1.raw.words.reduce((prev: { confidence: number; }, current: {
        confidence: number;
      }) => (prev.confidence > current.confidence) ? prev : current);
    }
    if (best2.raw?.words?.length > 1) {
      best2 = best2.raw.words.reduce((prev: { confidence: number; }, current: {
        confidence: number;
      }) => (prev.confidence > current.confidence) ? prev : current);
    }

    const code = best1.text;
    const score = best2.text;

    console.log(`[DEBUG] Code: ${code} | Score: ${score}`, best1, best2);

    isAlreadyReading.current = false;

    if (!code && !score) {
      return toast.error(t('leaderboard.reader.error'));
    } else if (isNaN(parseInt(score)) && isNaN(parseInt(code))) {
      return toast.error(t('leaderboard.reader.error'));
    } else if (!code || !score || isNaN(parseInt(score)) || isNaN(parseInt(score))) {
      toast.warn(t('leaderboard.reader.verify'));
    } else if (best1.confidence < 70 || best2.confidence < 70) {
      toast.warn(t('leaderboard.reader.notClear'));
    } else {
      toast.success(t('leaderboard.reader.success'));
    }
    setSubmitData({
      score,
      Mm: '',
      d: '',
      Ss: '',
      verificationCode: code,
      loading: false,
    });
    setIsScoreModalOpen(true);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: (files, event) => {
      if (!canAddScore?.current) {
        toast.error(t('leaderboard.wait.codeDisabled'));
        return event.preventDefault();
      }
      if (event.cancelable && (!isLogged || !playerCode || submitData.loading))
        return event.preventDefault();
      onDropAccepted(files).then(() => null);
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections.length === 0) return;
      toast.error(t('leaderboard.reader.wrongType'));
    },
  });

  const getTimeAPI = async (): Promise<number | null> => {
    try {
      const response = await fetch('https://worldtimeapi.org/api/timezone/UTC');
      const data = await response.json();
      return data.unixtime;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    setLeaderboard(null);
    setIsLoading(true);
    if (selectedEntry !== null)
      setSelectedEntry(null);
    const lb = setCLeaderboard(custom_id ?? id);
    if (!lb) {
      setIsLoading(false);
      setLeaderboardNotFound(true);
      navigate('/404');
      return;
    }

    if (!navigation.some((nav) => nav.href === `/${lb.custom_shorten}`)) {
      setDropdownItem('latest-tabs', {
        display: NavigationDisplayType.Singular,
        name: lb.name,
        href: lb.custom_shorten ? `/${lb.custom_shorten}` : `/lb/${lb.id}`,
        keep: false,
        exact: true,
      });
    }

    getTimeAPI().then((time) => {
      const [currentLBSettingsThis, nextLBSettingsThis] = currentAndNextLBSettings(lb, time);
      setCurrentLBSettings(currentLBSettingsThis);
      setNextLBSettings(nextLBSettingsThis);

      canAddScore.current = currentLBSettingsThis?.allow_submit ?? true;
      setAllowFetchCode(currentLBSettingsThis?.allow_code ?? false);

      if (currentLBSettingsThis?.leaderboard_show_type &&
        [
          LeaderboardLBSettingsLeaderboardShowType.None,
          LeaderboardLBSettingsLeaderboardShowType.Self,
        ].includes(currentLBSettingsThis?.leaderboard_show_type)) {
        setIsLoading(false);
        setLeaderboard({
          id: lb.id,
          teams: [],
          page: 0,
          total_pages: 1,
          total_entries: 0,
        });
        fetchSelfLeaderboard()
            .then(() => null);
        return;
      }

      setWaitForLogin(true);

      fetchLeaderboard({
        force: true,
      })
        .then(() => null);
    });
  }, [id, custom_id]);

  useEffect(() => {
    if (!allowFetchCode) return setPlayerCode(null);
    if (!isLogged) return setPlayerCode(null);
    fetchPlayerCode()
      .then(() => null);
    fetchSelfLeaderboard()
      .then(() => null);
  }, [isLogged, id, allowFetchCode]);

  useEffect(() => {
    if (!isLogged) {
      if (currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self) {
        setLeaderboard(prevState => {
          if (!prevState) return {
            id: currentLeaderboard?.id ?? custom_id ?? id ?? '0',
            teams: [],
            page: 0,
            total_pages: 1,
            total_entries: 0,
          };
          return {
            ...prevState,
            teams: [],
          };
        });
      }
    } else if (currentLBSettings && waitForLogin) {
      fetchSelfLeaderboard()
        .then(() => null);
    }
  }, [isLogged]);

  useEffect(() => {
    if (!leaderboard) return;
  }, [leaderboard]);

  useEffect(() => {
    const handleScroll = () => {
      setIsAboveScroll(window.scrollY > 350);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [leaderboard]);

  useEffect(() => {
    window.document.addEventListener('paste', (event) => {
      const items = event?.clipboardData?.items;
      if (items?.length === 0) return;
      const file = items?.[0].getAsFile();
      if (!file) return;
      onDropAccepted([file]).then(() => null);
    });
  }, []);

  const fetchLeaderboard = async ({
                                    page,
                                    force,
                                  }: {
    page?: number;
    force?: boolean;
  } = {}) => {
    if (!currentLBSettings) {
      setIsLoading(false);
      return;
    }
    if (!force && currentLBSettings?.leaderboard_show_type !== LeaderboardLBSettingsLeaderboardShowType.All)
      return;
    setIsLoading(true);
    setLeaderboardNotFound(false);
    setSelectedEntry(null);
    const params = new URLSearchParams();
    if (page) params.append('page', page.toString());
    if (currentLeaderboard?.leaderboard_asc) params.append('asc', 'true');
    const fetchedLeaderboard: LeaderboardType = await api.get(
      `/leaderboards/${currentLeaderboard?.id ?? custom_id ?? id}${params.toString() ? `?${params.toString()}` : ''}`,
    )
      .catch(() => null);
    if (!fetchedLeaderboard) {
      setLeaderboardNotFound(true);
      navigate('/404');
      return;
    }

    setLeaderboard(fetchedLeaderboard);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLeaderboard({
      force: true,
    })
      .then(() => null);
    fetchSelfLeaderboard()
      .then(() => null);
  }, [currentLBSettings]);

  const fetchSelfLeaderboard = async () => {
    if (!isLogged || !user?.id) {
      return;
    }
    if (!currentLBSettings) return;
    if (currentLBSettings?.leaderboard_show_type !== LeaderboardLBSettingsLeaderboardShowType.Self) return;
    setSelectedEntry(null);
    const params = new URLSearchParams();
    params.append('team', user.id);
    if (currentLeaderboard?.leaderboard_asc) params.append('asc', 'true');
    const fetchedLeaderboard: LeaderboardType = await api.get(
      `/leaderboards/${currentLeaderboard?.id ?? custom_id ?? id}${params.toString() ? `?${params.toString()}` : ''}`,
    )
      .catch(() => null);
    if (!fetchedLeaderboard) {
      setLeaderboardNotFound(true);
      navigate('/404');
      return;
    }

    setLeaderboard(prevState => {
      if (!prevState) return {
        id: fetchedLeaderboard.id,
        teams: fetchedLeaderboard.teams,
        page: 0,
        total_pages: 1,
        total_entries: fetchedLeaderboard.total_entries,
      };
      return {
        ...prevState,
        teams: fetchedLeaderboard.teams,
      };
    });
  };

  const submitScore = async () => {
    setSubmitData({
      ...submitData,
      loading: true,
    });
    let score = 0;
    const verificationCode = parseInt(submitData.verificationCode);

    if (currentLeaderboard?.type === LeaderboardTypeData.Time) {
      const Mm = parseInt(submitData.Mm);
      const Ss = parseInt(submitData.Ss);
      const d = parseInt(submitData.d);
      if (isNaN(Mm) || isNaN(Ss) || isNaN(d)) {
        toast.error(t('leaderboard.submit.error'));
        setSubmitData({
          ...submitData,
          loading: false,
        });
        return;
      }
      score = (Mm * 60 + Ss + d / 10) * 10;
    } else if (currentLeaderboard?.type === LeaderboardTypeData.Score) {
      score = parseInt(submitData.score);
    }

    const response = await api.post(
      `/leaderboards/${currentLeaderboard?.id ?? custom_id ?? id}/creative/result`,
      {
        noErrorToast: true,
        body: {
          score,
          verification_code: verificationCode,
        } as unknown as BodyInit,
      },
    )
      .catch((error) => {
        if (error?.error === 'errors.gg.wls.api.leaderboards.creative.rate_limit_exceeded') {
          toast.error(t('leaderboard.submit.rateLimit'));
        } else {
          toast.error(t('leaderboard.submit.error'));
        }
        return null;
      });
    if (!response) {
      setSubmitData({
        ...submitData,
        loading: false,
      });
      return;
    }

    setSubmitData({
      score: '',
      Mm: '',
      Ss: '',
      d: '',
      verificationCode: '',
      loading: false,
    });
    setIsScoreModalOpen(false);
    toast.success(t('leaderboard.submit.success'));
    if (currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.All) {
      await fetchLeaderboard();
    } else if (currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self) {
      await fetchSelfLeaderboard();
    }
  };

  const fetchPlayerCode = async () => {
    setPlayerCode(true);
    const fetchedPlayerCode: {
      code: string;
      map: string;
    } = await api.get(
      `/leaderboards/${currentLeaderboard?.id ?? custom_id ?? id}/creative/code`,
      {
        noErrorToast: true,
      },
    )
      .catch(() => null);
    if (!fetchedPlayerCode || !fetchedPlayerCode.code)
      return setPlayerCode(false);

    setPlayerCode(fetchedPlayerCode.code);
  };

  const setPage = async (page: number) => {
    if (!leaderboard) return;
    await fetchLeaderboard({
      page,
    });
  };

  const setModalEntry = async (entry: number) => {
    if (!leaderboard) return;
    if (selectedEntry === entry) return setSelectedEntry(null);
    setSelectedEntry(entry);
    setIsLeaderboardInfoOpen(false);
  };

  const copyMapCode = () => {
    navigator.clipboard.writeText(currentLeaderboard?.map_code ?? '0000-0000-0000')
      .then(() => {
        toast.success(t('leaderboard.code.copied'));
      })
      .catch(() => {
        toast.error(t('leaderboard.code.error'));
      });
  };

  const [nextDays, nextHours, nextMinutes, nextSeconds] = useCountdown(nextLBSettings?.after?.toISOString() ?? '', startDate);

  if (nextDays <= 0 && nextHours <= 0 && nextMinutes <= 0 && nextSeconds <= 0) {
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.location.reload();
    })();
  }

  const getEntry = (score: number): string => {
    if (currentLeaderboard?.type === LeaderboardTypeData.Time) {
      const Mm = Math.floor(score / 600);
      const Ss = Math.floor(score / 10) % 60;
      const d = score % 10;
      return `${Mm < 10 ? `0${Mm}` : Mm}:${Ss < 10 ? `0${Ss}` : Ss}.${d}`;
    } else {
      return score.toString();
    }
  };

  const LeaderboardInfo = () => {
    return (
      <>
        {
          isLeaderboardInfoOpen ? (
            <button
              onClick={() => setIsLeaderboardInfoOpen(false)}
              className={'flex flex-col w-full bg-white shadow rounded-lg p-4 dark:bg-gray-800 text-center justify-center items-center transition duration-500 ease-in-out transform hover:scale-[102%]'}>
              <h1
                className={'text-lg font-extrabold text-gray-900 dark:text-gray-100 flex items-center'}>
                {t('leaderboard.info.title')}
                <CursorArrowRaysIcon className={'h-5 w-5 ml-2'} />
              </h1>
              <h5 className={'text-sm text-gray-500 dark:text-gray-300 italic'}>
                {t('leaderboard.info.close')}
              </h5>
              <div className={'border-b-2 border-gray-200 dark:border-gray-700 my-2 w-full'} />
              {
                currentLeaderboard?.info?.map((info, index) => {
                  if (info.type === LeaderboardInfoHeaderType.Header) {
                    return (
                      <h2
                        key={index}
                        className={classNames('text-lg font-bold text-gray-900 dark:text-gray-100', info.class ?? '')}
                      >
                        {
                          (info.before?.length ? `${info.before} ` : '')
                          +
                          t(info.text)
                          +
                          (info.after?.length ? ` ${info.after}` : '')
                        }
                      </h2>
                    );
                  } else if (info.type === LeaderboardInfoHeaderType.Text) {
                    return (
                      <p
                        key={index}
                        className={classNames('text-sm text-gray-500 dark:text-gray-300', info.class ?? '')}
                      >
                        {
                          (info.before?.length ? `${info.before} ` : '')
                          +
                          t(info.text)
                          +
                          (info.after?.length ? ` ${info.after}` : '')
                        }
                      </p>
                    );
                  } else if (info.type === LeaderboardInfoHeaderType.SubHeader) {
                    return (
                      <h3
                        key={index}
                        className={classNames('text-md font-semibold text-gray-900 dark:text-gray-100', info.class ?? '')}
                      >
                        {
                          (info.before?.length ? `${info.before} ` : '')
                          +
                          t(info.text)
                          +
                          (info.after?.length ? ` ${info.after}` : '')
                        }
                      </h3>
                    );
                  } else if (info.type === LeaderboardInfoHeaderType.Empty) {
                    return (
                      <div
                        key={index}
                        className={classNames('h-2 w-full', info.class ?? '')}
                      />
                    );
                  } else if (info.type === LeaderboardInfoHeaderType.Button) {
                    return (
                      <Link to={info.href}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            type={'button'}
                            target={info.new_tab ? '_blank' : undefined}
                            key={index}
                            className={classNames('inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                              'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                              'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                              info.class ?? '',
                            )}
                      >
                        {t(info.text)}
                      </Link>
                    );
                  } else if (info.type === LeaderboardInfoHeaderType.Image) {
                    return (
                      <img
                        key={index}
                        src={info.src}
                        alt={info.alt}
                        className={classNames('w-fit h-fit', info.class ?? '')}
                      />
                    );
                  }

                  return null;
                })
              }
            </button>
          ) : (
            <button
              onClick={() => {
                setIsLeaderboardInfoOpen(true);
                setSelectedEntry(null);
              }}
              className={'flex flex-col w-full bg-white shadow rounded-lg p-4 dark:bg-gray-800 text-center justify-center items-center transition duration-500 ease-in-out transform hover:scale-105'}>
              <h1
                className={'text-lg font-extrabold text-gray-900 dark:text-gray-100 flex items-center'}>
                {t('leaderboard.info.title')}
                <CursorArrowRaysIcon className={'h-5 w-5 ml-2'} />
              </h1>
              <h5 className={'text-sm text-gray-500 dark:text-gray-300 italic'}>
                {t('leaderboard.info.open')}
              </h5>
            </button>
          )
        }
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{currentLeaderboard?.name ?? 'Leaderboard'} | FortniteRanking.com</title>
        <meta property={'og:title'}
              content={`${currentLeaderboard?.name ?? 'Leaderboard'} | FortniteRanking.com`} />
        {
          currentLeaderboard?.logo_url && (
            <meta property={'og:image'}
                  content={currentLeaderboard?.logo_url} />
          )
        }

      </Helmet>
      <div className={classNames(
        'MainLeaderboard w-screen min-h-[calc(100vh-10rem)]',
        currentLeaderboard?.background_settings?.background_image ? '' : 'dark:bg-gray-900 bg-gray-100',
      )}
           {...getRootProps()}
      >
        <>
          <div className={'w-full flex flex-col'}>
            <div
              className={'hidden'}
              id={'header'}>
              <img src={'/header_url.png'} alt={'header'} className={'w-full'} />
            </div>
            {
              currentLBSettings?.warning?.enabled ? (
                <div
                  className={'flex flex-row justify-center items-center bg-red-600 dark:bg-red-800 p-2 m-2 rounded-lg w-[calc(100%-4rem)] mx-auto'}>
                  <ExclamationTriangleIcon className={'h-8 w-8 mr-1 text-gray-100'} />
                  <div className={'text-lg text-gray-100'}>
                    {t(currentLBSettings.warning.text)}
                  </div>
                </div>
              ) : (
                <></>
              )
            }
            <div className={'Main h-full w-full'}>
              {/* Info */}
              <div
                id={'info'}
                className={
                  classNames(
                    'grid gap-8 gap-y-2 xl:gap-y-8 justify-between pb-2 m-auto lg:grid-cols-6 md:grid-cols-2 xl:grid-cols-6 2xl:grid-cols-6 grid-cols-1',
                    'mx-0 4xl:mx-[calc(60%-50vw)]',
                  )
                }>

                {/* Leaderboard Name | Total Players */}
                {
                  isLoadingWithLeaderboard && (
                    <div
                      className={'order-1 flex bg-white shadow rounded-lg p-4 grid-cols-3 sm:col-span-2 md:col-span-2 lg:col-span-6 xl:col-span-2 animate-pulse dark:bg-gray-800'}>
                      <div className={'items-center flex-1 py-1 flex justify-between w-full'}>
                        <h2 className={'bg-slate-500 h-10 rounded-md w-[60%]'} />
                        <div className={'text-sm text-gray-500 bg-slate-500 h-5 rounded-md w-[20%]'} />
                      </div>
                    </div>
                  )
                }
                {
                  leaderboard && (
                    <div
                      className={'order-1 flex bg-white shadow rounded-lg p-4 grid-cols-1 sm:col-span-2 md:col-span-2 lg:col-span-6 xl:col-span-2 dark:bg-gray-800'}>
                      <div className={'items-center flex-1 py-1 flex justify-between w-full'}>
                        <h2 className={'text-xl font-extrabold text-gray-900 dark:text-gray-100 flex items-center'}>
                          {
                            currentLeaderboard?.logo_url && (
                              <img src={currentLeaderboard.logo_url}
                                   alt={currentLeaderboard.name}
                                   className={'h-10 w-10 rounded-md mr-2'} />
                            )
                          }
                          {currentLeaderboard?.name ?? 'Leaderboard'}
                        </h2>
                        <div className={'text-sm text-gray-500 dark:text-gray-300'}>
                          {
                            currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.All ? (
                              <>
                                {t('leaderboard.teams', { count: leaderboard.total_entries })}
                              </>
                            ) : (
                              <>
                                {t('leaderboard.teamsHidden')}
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
                {/* Leaderboard Name | Total Players */}

                {/* Map Code */}
                {
                  isLoadingWithLeaderboard && (
                    <div
                      className={'order-2 flex bg-white shadow rounded-lg p-4 grid-cols-2 sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-2 xl:ml-2 animate-pulse dark:bg-gray-800'}>
                      <div
                        className={'items-center flex-1 py-1 flex justify-between w-full text-sm sm:text-base'}>
                        <div className={'flex flex-col w-full space-y-1'}>
                          <div className={'bg-slate-500 h-6 rounded-md w-[30%]'} />
                          <div className={'bg-slate-500 h-5 rounded-md w-[60%]'} />
                        </div>
                        <div className={'bg-slate-500 h-10 rounded-xl w-[40%]'} />
                      </div>
                    </div>
                  )
                }
                {
                  leaderboard && (
                    <div
                      className={'order-2 flex bg-white shadow rounded-lg p-4 grid-cols-2 sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-2 dark:bg-gray-800'}>
                      <div
                        className={'items-center flex-1 py-1 flex lg:block xl:flex justify-between w-full text-sm sm:text-base'}>
                        <div>
                          <div className={'text-md text-gray-900 font-semibold dark:text-gray-100'}>
                            {t('leaderboard.compete.title')}
                          </div>
                          <h4 className={'md:text-sm text-xs text-gray-500 dark:text-gray-300'}>
                            {t('leaderboard.compete.description')}
                          </h4>
                        </div>
                        <button
                          type={'button'}
                          className={classNames('inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                            'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                            'min-w-[170px]',
                            'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                          )}
                          onClick={copyMapCode}
                        >
                          <ClipboardIcon className={'h-5 w-5 mr-1'} />
                          {currentLeaderboard?.map_code ?? '0000-0000-0000'}
                        </button>
                      </div>
                    </div>
                  )
                }
                {/* Map Code */}

                {/* User Code | Add Score */}
                {
                  playerCode === true ? ( // Loading
                    <div
                      className={'order-3 flex bg-white shadow rounded-lg p-4 grid-cols-1 sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-2 animate-pulse dark:bg-gray-800'}>
                      <div className={'items-center flex-1 space-x-2 py-1 inline-flex justify-between w-full'}>
                        <div className={'flex flex-col w-full space-y-1'}>
                          <div className={'bg-slate-500 h-6 rounded-md w-[30%]'} />
                          <div className={'bg-slate-500 h-5 rounded-md w-[60%]'} />
                        </div>
                        <div className={'bg-slate-500 h-10 rounded-xl w-[40%]'} />
                      </div>
                    </div>
                  ) : ((playerCode === false || playerCode === null) && !isLogged) ? ( // Not Logged In
                    <div
                      className={'order-3 flex bg-white shadow rounded-lg p-4 grid-cols-1 sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-2 dark:bg-gray-800'}>
                      <div className={'items-center flex-1 py-1 flex justify-between w-full'}>
                        <div>
                          <div className={'text-md text-gray-900 font-semibold dark:text-gray-100'}>
                            {t('leaderboard.submit.login')}
                          </div>
                          <h4 className={'md:text-sm text-xs text-gray-500 dark:text-gray-300'}>
                            {t('leaderboard.submit.loginButton')}
                          </h4>
                        </div>
                        <Link
                          to={'https://drive.google.com/file/d/1FWxNU5LnIXOjLixicOUSOz_p5_m22G1x/preview'}
                          target={'_blank'}
                          type={'button'}
                          className={classNames('inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                            'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                            'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                          )}
                          onClick={() => null}
                        >
                          <QuestionMarkCircleIcon className={'h-5 w-5'} />
                        </Link>
                      </div>
                    </div>
                  ) : ( // Logged In
                    <div
                      className={'order-3 flex bg-white shadow rounded-lg p-4 grid-cols-1 sm:col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-2 dark:bg-gray-800'}>
                      <div className={'items-center flex-1 py-1 flex justify-between w-full'}>
                        <div className={'flex flex-col'}>
                          {
                            !allowFetchCode ? (
                              <div className={'text-md text-gray-900 dark:text-gray-100'}>
                                {
                                  nextLBSettings?.allow_code ? (
                                    <>
                                      {t('leaderboard.wait.codeFetchDisabledNext', {
                                        time: `${nextDays ? `${nextDays}d ` : ''}${nextHours ? `${nextHours}h ` : ''}${`${isNaN(nextMinutes) ? `-` : nextMinutes}m `}${`${isNaN(nextSeconds) ? `-` : nextSeconds}s`}`,
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      {t('leaderboard.wait.codeFetchDisabled')}
                                    </>
                                  )
                                }
                              </div>
                            ) : (
                              <div className={'text-md text-gray-900 dark:text-gray-100'}>
                                <Trans
                                  i18nKey={'leaderboard.submit.code'}
                                  values={{ code: playerCode }}
                                >
                                  Code:
                                  <span className={'text-gray-800 font-bold text-lg dark:text-gray-200'}>
                              {playerCode}
                            </span>
                                </Trans>
                                <Link
                                  to={'https://drive.google.com/file/d/1FWxNU5LnIXOjLixicOUSOz_p5_m22G1x/preview'}
                                  target={'_blank'}
                                  type={'button'}
                                  className={classNames('inline-flex justify-center rounded-md text-sm font-medium text-gray-700 flex-nowrap items-center',
                                    'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                                    'dark:text-gray-300 dark:hover:bg-gray-800',
                                  )}

                                >
                                  <QuestionMarkCircleIcon className={'h-5 w-5'} />
                                </Link>
                              </div>
                            )
                          }
                          <h4 className={'md:text-sm text-xs text-gray-500 dark:text-gray-300'}>
                            {
                              canAddScore?.current ? (
                                <>
                                  {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.submit.codeDescriptionTime' : 'leaderboard.submit.codeDescriptionScore')}
                                </>
                              ) : (
                                <>
                                  {
                                    nextLBSettings?.allow_submit ? (
                                      <>
                                        {t('leaderboard.wait.codeDisabledNext', {
                                          time: `${nextDays ? `${nextDays}d ` : ''}${nextHours ? `${nextHours}h ` : ''}${`${isNaN(nextMinutes) ? `-` : nextMinutes}m `}${`${isNaN(nextSeconds) ? `-` : nextSeconds}s`}`,
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {t('leaderboard.wait.codeDisabled')}
                                      </>
                                    )
                                  }
                                </>
                              )
                            }
                          </h4>
                        </div>
                        <button
                          disabled={!canAddScore?.current}
                          type={'button'}
                          className={classNames('inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                            'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                            'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                            'dark:disabled:hover:bg-gray-900 dark:disabled:bg-gray-900',
                          )}
                          onClick={() => {
                            if (!canAddScore?.current) {
                              toast.error(t('leaderboard.wait.codeDisabled'));
                              return;
                            }
                            setIsScoreModalOpen(true);
                          }}
                        >
                          <PlusCircleIcon className={'h-5 w-5 mr-1'} />
                          {t(
                            currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.submit.addTime' : 'leaderboard.submit.addScore',
                          )}
                        </button>
                      </div>
                    </div>
                  )
                }
                {/* User Code | Add Score */}
              </div>

              {/* Tournament Info - Small Screen */}
              <div className={'flex lg:hidden'}>
                <LeaderboardInfo />
              </div>
              {/* Tournament Info - Small Screen */}

              {/* Leaderboard */}
              <div
                id={'leaderboard'}
                className={
                  classNames(
                    'grid justify-between m-auto xl:space-x-2 grid-cols-8 gap-4 mt-2',
                    'mx-0 4xl:mx-[calc(60%-50vw)]',
                  )
                }>
                <div
                  className={'flex rounded-lg lg:col-span-6 col-span-8'}>
                  <div
                    className={'flex flex-col w-full bg-white shadow rounded-lg p-4 dark:bg-gray-800'}>
                    {leaderboard && (
                      <>
                        <div className={'flex flex-row items-center justify-between pb-3'}>
                          <div
                            className={'flex flex-row w-full items-center justify-between text-left space-x-2'}>
                            <div className={'flex flex-col text-sm space-y-1'}>
                              <button
                                type={'button'}
                                className={classNames('inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-xs sm:text-sm font-medium text-gray-700',
                                  'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                                  'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                                )}
                                onClick={async () => {
                                  if (currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.All) {
                                    await fetchLeaderboard({
                                      page: leaderboard?.page,
                                    });
                                  } else if (currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self) {
                                    await fetchSelfLeaderboard();
                                  }
                                }}
                              >
                                <ArrowPathIcon className={'sm:h-5 sm:w-5 mr-1 h-4 w-4'} />
                                <span>
                                  {t('leaderboard.refresh')}
                                </span>
                              </button>
                            </div>
                            <div className={'inline-flex'}>
                              <button
                                onClick={() => setPage(leaderboard.page - 1)}
                                className={classNames(
                                  'inline-flex justify-center rounded-l-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                                  'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                                  'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                                  'dark:disabled:hover:bg-gray-900 dark:disabled:bg-gray-900',
                                )}
                                disabled={!leaderboard || leaderboard.page === 0}
                              >
                                {t('leaderboard.prev')}
                              </button>
                              <button
                                onClick={() => setPage(leaderboard.page + 1)}
                                className={classNames(
                                  'inline-flex justify-center rounded-r-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                                  'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                                  'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                                  'dark:disabled:hover:bg-gray-900 dark:disabled:bg-gray-900',
                                )}
                                disabled={!leaderboard || leaderboard.page === leaderboard.total_pages - 1}
                              >
                                {t('leaderboard.next')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      id={'board'}
                      className={'flex flex-col w-full'}>
                      <div className={'inline-block min-w-full overflow-hidden'}>
                        <table className={'min-w-full leading-normal rounded-md'}>
                          <thead
                            className={'border-2 border-gray-200 bg-gray-100 text-gray-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100'}>
                          <tr className={'rounded-md'}>
                            <th
                              className={'px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider w-[30%]'}>
                              {t('leaderboard.rank')}
                            </th>
                            <th
                              className={'px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider w-[50%]'}>
                              {t('leaderboard.team')}
                            </th>
                            <th
                              className={'px-5 py-3 text-right text-xs font-semibold uppercase tracking-wider w-[20%]'}>
                              {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.time' : 'leaderboard.score')}
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            isLoading && (
                              <>
                                {[...Array(10)].map((_, index) => (
                                  <tr key={index}>
                                    <td className={'px-5 py-5 bg-white text-sm dark:bg-gray-800 dark:text-gray-100'}>
                                      <div className={'h-fit w-full rounded-md animate-pulse'}>
                                        <div className={'bg-slate-500 h-6 rounded-md w-[70%]'} />
                                      </div>
                                    </td>
                                    <td className={'px-5 py-5 bg-white text-sm dark:bg-gray-800 dark:text-gray-100'}>
                                      <div className={'h-fit w-full rounded-md animate-pulse'}>
                                        <div className={'bg-slate-500 h-6 rounded-md w-[70%]'} />
                                      </div>
                                    </td>
                                    <td className={'px-5 py-5 text-sm'}>
                                      <div className={'justify-end flex items-center w-full rounded-md animate-pulse'}>
                                        <div className={'bg-slate-500 h-6 rounded-md w-[70%]'} />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )
                          }
                          {
                            leaderboardNotFound && (
                              <tr>
                                <td className={'px-5 py-5 text-sm'} colSpan={4}>
                                  <div className={'flex flex-col items-center'}>
                                    <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                      {t('leaderboard.notFound')}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                          {
                            isLeaderboard && currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.All && (
                              <>
                                {
                                  leaderboard.teams.length === 0 && (
                                    <tr>
                                      <td className={'px-5 py-5 text-sm'} colSpan={4}>
                                        <div className={'flex flex-col items-center'}>
                                          <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                            {t('leaderboard.empty')}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }
                                {
                                  leaderboard.teams.map((entry, index) => (
                                    <tr key={index}
                                        onClick={() => setModalEntry(index)}
                                        className={'relative cursor-pointer hover:bg-red-100 hover:font-bold transition duration-150 ease-in-out' +
                                          ' dark:hover:bg-gray-700 dark:hover:text-gray-100'
                                          + (index === selectedEntry ? ' bg-red-100 font-semibold dark:bg-gray-700 dark:text-gray-100' : '')}>
                                      <td className={'px-5 py-5 text-sm'}>
                                        <div className={'flex items-center'}>
                                          <div className={'ml-3'}>
                                            <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                              {entry.place}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className={'px-5 py-5 text-sm'}>
                                        <div className={'flex items-center'}>
                                          <div className={'ml-0'}>
                                            <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                              {Object.values(entry.members).map((member => member.name)).join(', ')}
                                              {
                                                Object.values(entry.members).filter(member => member.id === user?.id).length > 0 && (
                                                  <span className={'text-gray-500 dark:text-gray-300 text-xs'}>
                                                  {' '}({t('leaderboard.you')})
                                                </span>
                                                )
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className={'px-5 py-5 text-sm'}>
                                        <div className={'flex items-right justify-end'}>
                                          <div className={'ml-3'}>
                                            <p
                                              className={'text-gray-900 whitespace-no-wrap font-semibold text-base dark:text-gray-100'}>
                                              {getEntry(entry.score)}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </>
                            )
                          }
                          {
                            isLeaderboard && currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self && (
                              <>
                                {
                                  isLogged && leaderboard.teams.length === 0 && (
                                    <tr>
                                      <td className={'px-5 py-5 text-sm'} colSpan={4}>
                                        <div className={'flex flex-col items-center'}>
                                          <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                            {t('leaderboard.emptySelf')}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }
                                {
                                  leaderboard.teams.map((entry, index) => (
                                    <tr key={index}
                                        onClick={() => setModalEntry(index)}
                                        className={'relative cursor-pointer hover:bg-red-100 hover:font-bold transition duration-150 ease-in-out' +
                                          ' dark:hover:bg-gray-700 dark:hover:text-gray-100'
                                          + (index === selectedEntry ? ' bg-red-100 font-semibold dark:bg-gray-700 dark:text-gray-100' : '')}>
                                      <td className={'px-5 py-5 text-sm'}>
                                        <div className={'flex items-center'}>
                                          <div className={'ml-3'}>
                                            <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                              {t('leaderboard.hidden')}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className={'px-5 py-5 text-sm'}>
                                        <div className={'flex items-center'}>
                                          <div className={'ml-0'}>
                                            <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                              {Object.values(entry.members).map((member => member.name)).join(', ')}
                                              {
                                                Object.values(entry.members).filter(member => member.id === user?.id).length > 0 && (
                                                  <span className={'text-gray-500 dark:text-gray-300 text-xs'}>
                                                  {' '}({t('leaderboard.you')})
                                                </span>
                                                )
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className={'px-5 py-5 text-sm'}>
                                        <div className={'flex items-right justify-end'}>
                                          <div className={'ml-3'}>
                                            <p
                                              className={'text-gray-900 whitespace-no-wrap font-semibold text-base dark:text-gray-100'}>
                                              {getEntry(entry.score)}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </>
                            )
                          }
                          {
                            !isLoading && (currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.None || currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self) && (
                              <tr>
                                <td className={'px-5 py-5 text-sm'} colSpan={4}>
                                  <div className={'flex flex-col items-center'}>
                                    <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                      {
                                        nextLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.All ? (
                                          <>
                                            {t('leaderboard.wait.showNoneNext', {
                                              time: `${nextDays ? `${nextDays}d ` : ''}${nextHours ? `${nextHours}h ` : ''}${`${isNaN(nextMinutes) ? `-` : nextMinutes}m `}${`${isNaN(nextSeconds) ? `-` : nextSeconds}s`}`,
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            {t('leaderboard.wait.showNone')}
                                          </>
                                        )
                                      }
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            )
                          }
                          </tbody>
                        </table>
                      </div>
                      <div
                        className={'px-5 py-5 bg-white border-t flex flex-col xs:space-y-2 items-center xs:justify-between dark:bg-gray-800 dark:border-gray-700'}>
                        <span className={'text-xs xs:text-sm text-gray-900 dark:text-gray-100'}>
                       {
                         !isLeaderboard ? (
                           <>
                             {t('leaderboard.page', { page: 0, pages: 0 })}
                           </>
                         ) : (
                           <>
                             {t('leaderboard.page', {
                               page: leaderboard.page + 1,
                               pages: leaderboard.total_pages || leaderboard.page + 1,
                             })}
                           </>
                         )
                       }
                    </span>
                        <div className={'inline-flex mt-2 xs:mt-0'}>
                          <button
                            onClick={() => setPage((leaderboard?.page ?? 0) - 1)}
                            className={classNames(
                              'inline-flex justify-center rounded-l-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                              'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                              'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                              'dark:disabled:hover:bg-gray-900 dark:disabled:bg-gray-900',
                            )}
                            disabled={!leaderboard || leaderboard.page === 0}
                          >
                            {t('leaderboard.prev')}
                          </button>
                          <button
                            onClick={() => setPage((leaderboard?.page ?? 0) + 1)}
                            className={classNames(
                              'inline-flex justify-center rounded-r-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                              'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                              'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800',
                              'dark:disabled:hover:bg-gray-900 dark:disabled:bg-gray-900',
                            )}
                            disabled={!leaderboard || leaderboard.page === leaderboard.total_pages - 1}
                          >
                            {t('leaderboard.next')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'lg:flex hidden rounded-lg col-span-2 h-full'}>
                  <div className={'w-full'}>
                    <LeaderboardInfo />
                    <div className={'right-info pr-16 pt-5 4xl:pr-[calc(75%-65vw)]' +
                      (isAboveScroll ? ' fixed bottom-auto top-[64px]' : ' absolute')}
                         style={{ width: '-webkit-fill-available' }}>
                      <div className={'lg:block hidden'}>
                        {
                          entry && (
                            <div
                              className={'flex flex-col w-full bg-white shadow rounded-lg p-4 transition duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg dark:bg-gray-800'}>
                              <div className={'flex flex-col w-full justify-center items-center gap-y-2'}>
                                <div
                                  className={'text-gray-500 flex-wrap break-all text-center w-full dark:text-gray-100'}>
                                  <h2
                                    className={'text-lg font-extrabold justify-center text-center p-1 text-gray-900 dark:text-gray-100'}>
                                  </h2>
                                  <p
                                    className={'text-gray-800 whitespace-no-wrap px-1 py-1 font-bold dark:text-gray-100'}>
                                    {Object.values(entry.members).map((member => member.name)).join(', ')}
                                  </p>
                                  <div className={'flex items-center justify-center w-full flex-col pt-2 rounded-md'}>
                                    <table className={'w-full rounded-md'}>
                                      <thead
                                        className={'border-b-2 border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-100'}>
                                      <tr>
                                        <th
                                          className={'px-2 py-2 bg-gray-100 text-left text-xs font-semibold uppercase tracking-wider dark:bg-gray-700'}>
                                          {t('leaderboard.rank')}
                                        </th>
                                        <th
                                          className={'px-2 py-2 bg-gray-100 text-right text-xs font-semibold uppercase tracking-wider dark:bg-gray-700'}>
                                          {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.time' : 'leaderboard.score')}
                                        </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr className={'bg-gray-100 dark:bg-gray-700'}>
                                        <td className={'px-2 py-2 text-sm'}>
                                          <div className={'flex items-center'}>
                                            <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                              {
                                                currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self ? (
                                                  <>
                                                    {t('leaderboard.hidden')}
                                                  </>
                                                ) : (
                                                  <>
                                                    {entry.place}
                                                  </>
                                                )
                                              }
                                            </p>
                                          </div>
                                        </td>
                                        <td className={'px-2 py-2 text-sm'}>
                                          <div className={'flex items-center justify-end'}>
                                            <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                              {getEntry(entry.score)}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={'lg:hidden fixed inset-0 z-10 overflow-y-auto bg-gray-800 bg-opacity-75 opacity-100' + (selectedEntry !== null ? '' : ' hidden')}
                  onClick={() => setSelectedEntry(null)}
                >
                  <div className={'right-info w-full'}>
                    <div
                      className={'flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center lg:block lg:p-0'}
                      role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}>
                      {
                        entry && (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className={'flex flex-col w-full bg-white shadow rounded-lg p-4 scale-95 transition duration-500 ease-in-out transform hover:scale-100 hover:shadow-lg dark:bg-gray-800'}>
                            <div className={'flex flex-col w-full justify-center items-center gap-y-2'}>
                              <div
                                className={'text-gray-500 flex-wrap break-all text-center w-full dark:text-gray-100'}>
                                <h2
                                  className={'text-lg font-extrabold justify-center text-center p-1 text-gray-900 dark:text-gray-100'}>
                                </h2>
                                <p
                                  className={'text-gray-800 whitespace-no-wrap px-1 py-1 font-bold dark:text-gray-100'}>
                                  {Object.values(entry.members).map((member => member.name)).join(', ')}
                                </p>
                                <div className={'flex items-center justify-center w-full flex-col pt-2 rounded-md'}>
                                  <table className={'w-full rounded-md'}>
                                    <thead
                                      className={'border-b-2 border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-100'}>
                                    <tr>
                                      <th
                                        className={'px-2 py-2 bg-gray-100 text-left text-xs font-semibold uppercase tracking-wider dark:bg-gray-700'}>
                                        {t('leaderboard.rank')}
                                      </th>
                                      <th
                                        className={'px-2 py-2 bg-gray-100 text-right text-xs font-semibold uppercase tracking-wider dark:bg-gray-700'}>
                                        {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.time' : 'leaderboard.score')}
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={'bg-gray-100 dark:bg-gray-700'}>
                                      <td className={'px-2 py-2 text-sm'}>
                                        <div className={'flex items-center'}>
                                          <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                            {
                                              currentLBSettings?.leaderboard_show_type === LeaderboardLBSettingsLeaderboardShowType.Self ? (
                                                <>
                                                  {t('leaderboard.hidden')}
                                                </>
                                              ) : (
                                                <>
                                                  {entry.place}
                                                </>
                                              )
                                            }
                                          </p>
                                        </div>
                                      </td>
                                      <td className={'px-2 py-2 text-sm'}>
                                        <div className={'flex items-center justify-end'}>
                                          <p className={'text-gray-900 whitespace-no-wrap dark:text-gray-100'}>
                                            {getEntry(entry.score)}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>

              {/*  Add Score Modal*/}
              <Transition.Root show={isScoreModalOpen} as={Fragment}>
                <Transition.Child
                  as={Fragment}
                  enter={'transform transition ease-in-out duration-300'}
                  enterFrom={'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}
                  enterTo={'opacity-100 translate-y-0 sm:scale-100'}
                  leave={'transform transition ease-in-out duration-300'}
                  leaveFrom={'opacity-100 translate-y-0 sm:scale-100'}
                  leaveTo={'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'}
                >
                  <div
                    className={'fixed inset-0 z-10 overflow-y-auto bg-gray-800 bg-opacity-75 opacity-100'}
                    onClick={() => setIsScoreModalOpen(false)}
                  >
                    <div
                      className={'flex items-end justify-center pt-20 px-4 text-center lg:block lg:p-0'}
                      role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}>
                      <div
                        className={'inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-16 sm:align-middle sm:max-w-lg sm:w-full 2xl:min-w-[70vw] min-w-[80vw] dark:bg-gray-900 dark:shadow-2xl'}
                        role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}
                        onClick={(e) => e.stopPropagation()}>
                        <div className={'bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-md dark:bg-gray-900'}>
                          <div className={'mt-3 text-center justify-center flex flex-col'}>
                            <h1 className={'text-3xl leading-6 font-bold text-gray-900 uppercase dark:text-gray-100'}>
                              {t(currentLeaderboard?.type === LeaderboardTypeData.Score ? 'leaderboard.submit.popup.titleScore' : 'leaderboard.submit.popup.titleTime')}
                            </h1>
                            <div className={'mt-2'}>
                              <p className={'text-base text-gray-800 dark:text-gray-200'}>
                                <Trans
                                  i18nKey={currentLeaderboard?.type === LeaderboardTypeData.Score ? 'leaderboard.submit.popup.descriptionCodeScore' : 'leaderboard.submit.popup.descriptionCodeTime'}
                                  values={{ code: playerCode }}
                                >
                                  Once providing your player code <b>({playerCode})</b> in Fortnite map - you will be
                                  able
                                  to submit your score.
                                </Trans>
                                <br />
                                {
                                  currentLeaderboard?.type === LeaderboardTypeData.Score && (
                                <Trans
                                  i18nKey={'leaderboard.submit.popup.descriptionScore'}
                                >
                                  You should see your <b>score</b> and <b>verification code</b> on the map.
                                </Trans>
                                  )
                                }
                                {
                                  currentLeaderboard?.type === LeaderboardTypeData.Time && (
                                    <Trans
                                      i18nKey={'leaderboard.submit.popup.descriptionTime'}
                                    >
                                      You should see your <b>time</b> and <b>verification code</b> on the map.
                                    </Trans>
                                  )
                                }
                                {
                                  !nextLBSettings?.allow_submit && !isNaN(nextSeconds) && (
                                    <>
                                      <br />
                                      <span className={'text-red-300'}>
                                        {t('leaderboard.wait.submitDisabledNext', {
                                          time: `${nextDays ? `${nextDays}d ` : ''}${nextHours ? `${nextHours}h ` : ''}${`${isNaN(nextMinutes) ? `-` : nextMinutes}m `}${`${isNaN(nextSeconds) ? `-` : nextSeconds}s`}`,
                                        })}
                                      </span>
                                    </>
                                  )
                                }
                              </p>
                            </div>
                            {
                              currentLeaderboard?.score_settings?.enabled_type === LeaderboardScoreSettingsEnabledType.All && (
                                <div className={'mt-4'}>
                                  <h2 className={'text-xl font-semibold text-gray-900 uppercase dark:text-gray-100'}>
                                    {t(currentLeaderboard?.type === LeaderboardTypeData.Score ? 'leaderboard.submit.popup.submitScore' : 'leaderboard.submit.popup.submitTime')}
                                  </h2>
                                  <div className={'mt-2'}>
                                    <p className={'text-sm text-gray-800 dark:text-gray-200'}>
                                      <Trans
                                        i18nKey={'leaderboard.submit.popup.submitOptions'}
                                      >
                                        You can either submit your score using the <b>PLAIN VERSION</b> or by attaching a
                                        screenshot from the game <b>(SCREENSHOT VERSION)</b>.
                                      </Trans>
                                    </p>
                                  </div>
                                </div>
                              )
                            }
                            <div className={'mt-2'}>
                              <div id={'plain-version'}
                                   className={'flex flex-col border border-gray-400 p-4 rounded-md dark:border-gray-700'}>
                                <h3 className={'text-lg font-semibold text-gray-900 dark:text-gray-100'}>
                                  {
                                    currentLeaderboard?.score_settings?.enabled_type === LeaderboardScoreSettingsEnabledType.All ? (
                                      <>
                                        {t('leaderboard.submit.popup.plain')}
                                      </>
                                    ) : (
                                      <>
                                        {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.submit.addTime' : 'leaderboard.submit.addScore')}
                                      </>
                                    )
                                  }
                                </h3>
                                <p className={'text-sm text-gray-800 dark:text-gray-200'}>
                                  {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.submit.popup.plainDescriptionTime' : 'leaderboard.submit.popup.plainDescriptionScore')}
                                </p>
                                <div id={'plain-version-form'}
                                     className={'mt-2 flex flex-col items-center justify-center'}>
                                  <div className={'grid grid-cols-1 gap-2 md:grid-cols-1'}>
                                    <div
                                      className={'flex flex-col sm:flex-row space-x-4 items-center justify-between'}>
                                      <label htmlFor={'score'}
                                             className={'text-xs sm:text-sm font-semibold text-gray-800 dark:text-gray-300'}>
                                        {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.submit.popup.time' : 'leaderboard.submit.popup.score')}
                                      </label>
                                      {
                                        currentLeaderboard?.type === LeaderboardTypeData.Score && (
                                      <input
                                        type={'number'}
                                        id={'score'}
                                        name={'score'}
                                        placeholder={t('leaderboard.submit.popup.score')}
                                        className={'mt-1 p-2 rounded-md border border-gray-300 focus:ring-gray-500 focus:border-gray-500 block shadow-sm text-xs sm:text-sm dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700'}
                                        value={submitData.score}
                                        onChange={(e) => setSubmitData({
                                          ...submitData,
                                          score: e.target.value,
                                        })}
                                        disabled={submitData.loading}
                                      />
                                        )
                                      }
                                      {
                                        currentLeaderboard?.type === LeaderboardTypeData.Time && (
                                          <div className={'flex items-center max-w-[150px] justify-between'}>
                                            <input
                                              type={'number'}
                                              id={'time-minutes'}
                                              name={'time-minutes'}
                                              placeholder={'Mm'}
                                              style={{
                                                textAlign: 'center',
                                              }}
                                              className={'mt-1 p-2 rounded-md border border-gray-300 focus:ring-gray-500 focus:border-gray-500 block shadow-sm text-xs sm:text-sm dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700 w-[30%]'}
                                              value={submitData.Mm}
                                              onChange={(e) => {
                                                const value = parseInt(e.target.value);
                                                if ((isNaN(value) || value < 0 || value > 999) && e.target.value !== '') {
                                                  return;
                                                }
                                                setSubmitData({
                                                  ...submitData,
                                                  Mm: e.target.value,
                                                });
                                              }}
                                              disabled={submitData.loading}
                                            />
                                            <span className={'text-gray-800 dark:text-gray-200'}>
                                              :
                                            </span>
                                            <input
                                              type={'number'}
                                              id={'time-seconds'}
                                              name={'time-seconds'}
                                              placeholder={'Ss'}
                                              className={'mt-1 p-2 rounded-md border border-gray-300 focus:ring-gray-500 focus:border-gray-500 block shadow-sm text-xs sm:text-sm dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700 w-[30%]'}
                                              value={submitData.Ss}
                                              style={{
                                                textAlign: 'center',
                                              }}
                                              onChange={(e) => {
                                                const value = parseInt(e.target.value);
                                                if ((isNaN(value) || value < 0 || value > 59) && e.target.value !== '') {
                                                  return;
                                                }
                                                setSubmitData({
                                                  ...submitData,
                                                  Ss: e.target.value,
                                                });
                                              }}
                                              disabled={submitData.loading}
                                            />
                                            <span className={'text-gray-800 dark:text-gray-200'}>
                                              .
                                            </span>
                                            <input
                                              type={'number'}
                                              id={'time-d'}
                                              name={'time-d'}
                                              placeholder={'d'}
                                              className={'mt-1 p-2 rounded-md border border-gray-300 focus:ring-gray-500 focus:border-gray-500 block shadow-sm text-xs sm:text-sm dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700 w-[20%]'}
                                              value={submitData.d}
                                              onChange={(e) => {
                                                const value = parseInt(e.target.value);
                                                if ((isNaN(value) || value < 0 || value > 9) && e.target.value !== '') {
                                                  return;
                                                }
                                                setSubmitData({
                                                  ...submitData,
                                                  d: e.target.value,
                                                });
                                              }}
                                              disabled={submitData.loading}
                                            />
                                          </div>
                                        )
                                      }
                                    </div>
                                    <div
                                      className={'flex flex-col sm:flex-row space-x-4 items-center justify-between text-center'}>
                                      <label htmlFor={'verification-code'}
                                             className={'text-xs sm:text-sm font-semibold text-gray-800 dark:text-gray-300'}>
                                        {t('leaderboard.submit.popup.verificationCode')}
                                      </label>
                                      <input
                                        type={'number'}
                                        id={'verification-code'}
                                        name={'verification-code'}
                                        placeholder={'Verification Code'}
                                        className={'mt-1 p-2 rounded-md border border-gray-300 focus:ring-gray-500 focus:border-gray-500 block shadow-sm text-xs sm:text-sm dark:text-gray-200 dark:bg-gray-800 dark:border-gray-700'}
                                        value={submitData.verificationCode}
                                        onChange={(e) => setSubmitData({
                                          ...submitData,
                                          verificationCode: e.target.value,
                                        })}
                                        disabled={submitData.loading}
                                      />
                                    </div>
                                    <div>
                                      <button
                                        type={'button'}
                                        className={classNames('inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 flex-nowrap items-center',
                                          'hover:bg-gray-50 focus:outline-none justify-between transform transition duration-500 ease-in-out enabled:hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed',
                                          'dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:disabled:hover:bg-gray-900',
                                        )}
                                        onClick={submitScore}
                                        disabled={
                                          currentLeaderboard?.type === LeaderboardTypeData.Score ?
                                            (!submitData.score || !submitData.verificationCode) || submitData.loading
                                            :
                                            (!submitData.Mm || !submitData.Ss || !submitData.d || !submitData.verificationCode) || submitData.loading
                                        }
                                      >
                                        {t(currentLeaderboard?.type === LeaderboardTypeData.Time ? 'leaderboard.submit.popup.submitTime' : 'leaderboard.submit.popup.submitScore')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {
                                currentLeaderboard?.score_settings?.enabled_type === LeaderboardScoreSettingsEnabledType.All && (
                                  <>
                                    <div
                                      className={'flex capitalize items-center justify-center w-full p-2 font-semibold text-gray-800 dark:text-gray-200'}>
                                      {t('leaderboard.submit.popup.or')}
                                    </div>
                                    <div id={'screenshot-version'}
                                         className={'flex flex-col border border-gray-400 p-4 rounded-md dark:border-gray-700'}>
                                      <h3 className={'text-lg font-semibold text-gray-900 dark:text-gray-100'}>
                                        {t('leaderboard.submit.popup.screenshot')}
                                      </h3>
                                      <p className={'text-sm text-gray-800 dark:text-gray-200'}>
                                        {t('leaderboard.submit.popup.screenshotDescription')}
                                      </p>
                                      <div id={'screenshot-version-form'}
                                           className={'mt-2 flex flex-col items-center justify-center'}>
                                        <div className={'flex items-center justify-center w-full'}>
                                          <label htmlFor={'dropzone-file'}
                                                 className={classNames('flex flex-col items-center justify-center w-full p-4 border-2 border-gray-300 border-dashed rounded-md',
                                                   isDragActive ? 'border-gray-500 dark:border-gray-400' : 'border-gray-300 dark:border-gray-600 cursor-pointer')}
                                          >
                                            <div className={'flex flex-col items-center justify-center pt-5 pb-6'}>
                                              {
                                                !isDragActive ? (
                                                  <>
                                                    <ArrowUpTrayIcon
                                                      className={'h-8 w-8 text-gray-600 dark:text-gray-400'} />
                                                    <p className={'text-sm text-gray-600 dark:text-gray-400'}>
                                                      <Trans
                                                        i18nKey={'leaderboard.submit.popup.screenshotUpload'}
                                                      >
                                                        <span className={'font-semibold'}>Click to upload</span>,
                                                        <span className={'font-semibold'}>drag and drop</span>
                                                        or <span className={'font-semibold'}>paste (Ctrl+V)</span>
                                                        a screenshot from the game
                                                      </Trans>
                                                    </p>
                                                    <p className={'text-xs text-gray-500 dark:text-gray-500'}>
                                                      {t('leaderboard.submit.popup.types')}
                                                    </p>
                                                  </>
                                                ) : (
                                                  <>
                                                    <ArrowDownTrayIcon
                                                      className={'h-8 w-8 text-gray-600 dark:text-gray-200'} />
                                                    <p className={'text-sm text-gray-600 dark:text-gray-300'}>
                                                      <Trans
                                                        i18nKey={'leaderboard.submit.popup.screenshotDrop'}
                                                      >
                                                        <span className={'font-semibold'}>Drop</span> to upload
                                                      </Trans>
                                                    </p>
                                                    <p className={'text-xs text-gray-500 dark:text-gray-400'}>
                                                      {t('leaderboard.submit.popup.types')}
                                                    </p>
                                                  </>
                                                )
                                              }
                                            </div>
                                            <input
                                              type={'file'}
                                              id={'dropzone-file'}
                                              name={'dropzone-file'}
                                              className={'sr-only'}
                                              onPasteCapture={(e) => {
                                                console.log(e);
                                              }}
                                              {...getInputProps()}
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </Transition.Root>

              {/*  Drop File */}
              {
                isDragActive && !isScoreModalOpen && isLogged && (
                  <div
                    className={'fixed inset-0 z-10 overflow-y-auto bg-gray-800 bg-opacity-75 opacity-100'}
                    onClick={() => setIsScoreModalOpen(false)}
                  >
                    <div
                      className={'flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center lg:block lg:p-0'}
                      role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}>
                      <div
                        className={'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-16 sm:align-middle sm:max-w-lg sm:w-full 2xl:min-w-[70vw] min-w-[80vw] p-4 dark:bg-gray-900 dark:shadow-2xl'}
                        role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}
                        onClick={(e) => e.stopPropagation()}>
                        <label htmlFor={'dropzone-file'}
                               className={classNames('flex flex-col items-center justify-center w-full p-4 border-2 border-gray-300 border-dashed rounded-md',
                                 isDragActive ? 'border-gray-500 dark:border-gray-400' : 'border-gray-300 dark:border-gray-600')}
                        >
                          <div className={'flex flex-col items-center justify-center pt-5 pb-6'}>
                            {
                              !isDragActive ? (
                                <>
                                  <ArrowUpTrayIcon className={'h-8 w-8 text-gray-600 dark:text-gray-400'} />
                                  <p className={'text-sm text-gray-600 dark:text-gray-400'}>
                                    <Trans
                                      i18nKey={'leaderboard.submit.popup.screenshotUpload'}
                                    >
                                      <span className={'font-semibold'}>Click to upload</span>,
                                      <span className={'font-semibold'}>drag and drop</span>
                                      or <span className={'font-semibold'}>paste (Ctrl+V)</span>
                                      a screenshot from the game
                                    </Trans>
                                  </p>
                                  <p className={'text-xs text-gray-500 dark:text-gray-500'}>
                                    {t('leaderboard.submit.popup.types')}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <ArrowDownTrayIcon className={'h-8 w-8 text-gray-600 dark:text-gray-200'} />
                                  <p className={'text-sm text-gray-600 dark:text-gray-300'}>
                                    <Trans
                                      i18nKey={'leaderboard.submit.popup.screenshotDrop'}
                                    >
                                      <span className={'font-semibold'}>Drop</span> to upload
                                    </Trans>
                                  </p>
                                  <p className={'text-xs text-gray-500 dark:text-gray-400'}>
                                    {t('leaderboard.submit.popup.types')}
                                  </p>
                                </>
                              )
                            }
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                isDragActive && !isScoreModalOpen && !isLogged && (
                  <div
                    className={'fixed inset-0 z-10 overflow-y-auto bg-gray-800 bg-opacity-75 opacity-100'}
                    onClick={() => setIsScoreModalOpen(false)}
                  >
                    <div
                      className={'flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center lg:block lg:p-0'}
                      role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}>
                      <div
                        className={'inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-16 sm:align-middle sm:max-w-lg sm:w-full 2xl:min-w-[70vw] min-w-[80vw] p-4'}
                        role={'dialog'} aria-modal={'true'} aria-labelledby={'modal-headline'}
                        onClick={(e) => e.stopPropagation()}>
                        <div className={'flex flex-col w-full justify-center items-center gap-y-2'}>
                          <div className={'text-gray-500 flex-wrap break-all text-center w-full'}>
                            <h2
                              className={'text-lg font-extrabold justify-center text-center p-1 text-gray-900'}>
                              You need to login to submit your score!
                            </h2>
                            <p className={'text-gray-800 whitespace-no-wrap px-1 py-1 font-bold'}>
                              Please use the "Sign in with Epic Games" button to login and submit your score afterwards.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </>
      </div>
    </>
  );
}
